import { Button, Card, CardContent, Grid, IconButton, TextField, Tooltip, Typography, Menu, MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';
import { CloudDownload, PlayArrow, Sync, Replay, Refresh, Info } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';

const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;
const Input = styled('input')({
  display: 'none',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const JobExtractor = () => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [buttonLabel, setButtonLabel] = useState('Upload File'); 
  const [rows, setRows] = useState([]); 
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [disableButton, setDisableButton] = useState(false);
  const [buttonClass, setButtonClass] = useState('custom-button');
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleIsLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false); 
  
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setContent('');
    setFileNames([]);
    setButtonLabel('Upload File');
    setOpen(false);

    const fileInput = document.getElementById('file-upload');
    if (fileInput) {
      fileInput.value = ''; 
    }
  };


  const userDetails = JSON.parse(localStorage.getItem("userdetails"));
  const username = userDetails.user.name
  const userid = userDetails.user.id
  const handleSelectionChange = (newSelection) => {
    console.log('Selected Row IDs:', newSelection); 
    const selectedData = rows.filter((row) => newSelection.includes(row.id));
    console.log('Selected Row Data:', selectedData); // Log the full row data
    setSelectedRows(selectedData); // Update state with full row data
  };
  const handleMenuClick = (event, row) => {
    setAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedRow(null);
  };

  const fetchJobsData = async () => {
    try{
      const response = await fetch(`${baseURI}job_analysis/businesstool/je_get_all_files?userid=${userid}`, {
        method: 'GET'
      }); 

      if (response.ok) {
        const data = await response.json(); 

        setRows(data.details)
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    }catch(error){
      console.error('fetching the error users',error);
    }
  }
  useEffect(() => {
    fetchJobsData()
  },[])


  const handleDownloadBase64 = async (row) => {
    try {
      console.log("Getting job:", row.fileid);
     
      const response = await fetch(`${baseURI}job_analysis/businesstool/jefn_download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileid: row.fileid }),
      });
      fetchJobsData(); 
      if (response.ok) {
        const result = await response.json();
        toast.success('Job data retrieved successfully!');
  
        const base64Data = result.base64;
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);
  
        const blob = new Blob([byteArray], { type: 'text/csv' });
        const downloadUrl = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `job_${row.fileid}.csv`; // CSV file extension
        link.click();
  
        URL.revokeObjectURL(downloadUrl);
  
        toast.success('CSV download triggered.');
      } else {
        const errorData = await response.json();
        toast.error(`Failed to retrieve job: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error processing jobs:', error);
      toast.error('An error occurred while processing jobs.');
    }
  };
  
  useEffect(() => {
    if (!isProcessing) return;

    let pollingInterval;

    const pollJobsData = async () => {
      await fetchJobsData();
      const processingJobs = rows.some((row) => row.status === 2);
      if (!processingJobs) {
        clearInterval(pollingInterval); 
        setIsProcessing(false);
        toast.success("All jobs are now completed or idle.");
      }
    };

    pollingInterval = setInterval(pollJobsData, 5000);

    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [isProcessing, rows]);
  

  const sendDataToBackend = async () => {
    setProgress(10);
    setCircleIsLoading(true)
    try {
      setProgress(30);

      const docList = fileNames.map((name, index) => ({
        filename: name,
        base64: content[index], // Ensure `content` matches the order of `fileNames`
      }));
      const response = await fetch(`${baseURI}job_analysis/businesstool/je_file_upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userid, username, doc_list: docList}), 
      });
      setProgress(40);
      

      console.log(response)
      if (!response.ok) {
        if(response.status === 500){
          toast.error(response.statusText);
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        setProgress(50);
        toast.success('File uploaded successfully!');


        fetchJobsData()

        setTimeout(() => {
          setProgress(70);
          setTimeout(() => {
            setProgress(100);
            setTimeout(() => {          
              setCircleIsLoading(false);
            }, 300);
          }, 500);
        },800);

      }

      handleClose();
    } catch (error) {
      console.log(error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleFileUpload = (event) => {
    const files = event.target.files; // Get all selected files
    
    if (files.length === 0) {
      toast.error("No file selected");
      return;
    }

    setFileNames(Array.from(files).map((file) => file.name));
  
    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };
  
    Array.from(files).forEach((file) => {
      if (file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const rawData = XLSX.utils.sheet_to_json(sheet, { raw: true, defval: null });
  
          const filteredData = rawData.map((row) => {
            const cleanedRow = {};
            Object.keys(row).forEach((key) => {
              if (!key.startsWith("__EMPTY")) {
                cleanedRow[key] = row[key];
              }
            });
            return cleanedRow;
          });
  
          if (filteredData.length > 200) {
            setDisableButton(true);
            setButtonClass('custom-button custom-disabled');
            toast.error(`Each file should only contain 200 records. More than that the file will not process`);
            return;
          } else {
            setDisableButton(false);
            setButtonClass('button-t button-j');
          }
  
          const base64String = await toBase64(file);
          console.log(`Base64 XLSX String (${file.name}):`, base64String);
          setContent((prevContent) => [...prevContent, base64String]); // Store multiple files' content
          toast.success(`File uploaded and parsed successfully!`);
        };
  
        reader.readAsArrayBuffer(file);
      } else if (file.name.endsWith('.csv')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvContent = e.target.result;
          const rows = csvContent
            .split('\n')
            .filter((row) => row.trim() !== '') // Remove empty rows
            .map((row) => row.split(',')); // Split CSV content into arrays
  
          if (rows.length > 200) {
            setDisableButton(true);
            setButtonClass('custom-button custom-disabled');
            toast.error(`Each file should only contain 200 records. More than that the file will not process.`);
            return;
          } else {
            setDisableButton(false);
            setButtonClass('button-t button-j');
          }
  
          const headers = rows[0]; // First row as headers
          const data = rows.slice(1).map((row) =>
            headers.reduce((acc, header, index) => {
              acc[header] = row[index] || null;
              return acc;
            }, {})
          );
  
          const base64String = await toBase64(file);
          console.log(`Base64 CSV String (${file.name}):`, base64String);
          setContent((prevContent) => [...prevContent, base64String]); // Store multiple files' content
          toast.success(`File uploaded and parsed successfully!`);
        };
  
        reader.readAsText(file);
      } else {
        toast.error(`Invalid file type it must be .xlsx or .csv file.`);
      }
    });
  };


  const handleReRun = async (row) => {

    const formattedData = [
      {
        id: row.id,
        fileid: row.fileid,
        fileurl: row.file_url,
      },
    ];
 
    toast("Processing the following job", {
      icon: <Info />,
      style: {
        borderRadius: '10px',
        background: '#0fc9ee',
        color: '#fff',
      },
    })

    fetchJobsData(); 
    setIsProcessing(true)
    try {
      const response = await fetch(`${baseURI}job_analysis/businesstool/job_extractor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: formattedData }),
      });

    
      
      if (response.ok) {
        // const result = await response.json();
        setIsProcessing(true);
        toast.success('Re-run successful!');
      } else {
    
        const errorData = await response.json(); 
        if (response.status === 500) {
          console.log('Error message:', errorData.message);
          toast.error(`Error: ${errorData.message}`);
        } else {
          console.error(`Unexpected error: ${response.status}`);
          toast.error(`Unexpected error: ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error('Error re-running job:', error);
    }
    
  }
  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}>No records</Box>
  );

  const handleRefresh = (row) => {
    console.log('Refreshing row:', row);
    toast("Refreshing the table", {
      icon: <Info />,
      style: {
        borderRadius: '10px',
        background: '#ee9d0f',
        color: '#fff',
      }
  })
   
    fetchJobsData(); 
  };

  // Data Columns
  const columns = [
    { field: 'username', headerName: 'User', flex: 1 },
    { field: 'total_records', headerName: 'Total Records', flex: 1 },
    { field: 'total_done', headerName: 'Total Records Processed', flex: 1 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => {
        let style = {};
        let label = '';
    
        switch (params.value) {
          case 0:
            style = { backgroundColor: '#d3d3d3', color: 'black', borderRadius: '5px', padding: '5px 10px' };
            label = 'Idle';
            break;
          case 1:
            style = { backgroundColor: '#ffa500', color: 'white', borderRadius: '5px', padding: '5px 10px' };
            label = 'In Queue';
            break;
          case 2:
            style = { backgroundColor: '#007bff', color: 'white', borderRadius: '5px', padding: '5px 10px' };
            label = 'Processing';
            break;
          case 3:
            style = { backgroundColor: '#28a745', color: 'white', borderRadius: '5px', padding: '5px 10px' };
            label = 'Completed';
            break;
          default:
            style = { backgroundColor: '#dc3545', color: 'white', borderRadius: '5px', padding: '5px 10px' };
            label = 'Unknown';
        }
    
        return (
          <span style={style}>
            {label}
          </span>
        );
      },
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderCell: (params) => {
        const status = params.row.status;
  
        return (
          <>
            {status === 0 &&  (
              <Tooltip title="Run Job">
                <IconButton
                  aria-label="run-job"
                  onClick={() => handleReRun(params.row)}
                  sx={{ color: 'blue' }}
                >
                  <PlayArrow />
                </IconButton>
              </Tooltip>
            )}
            {/* {status === 1 && (
              <Tooltip title="Process">
                <IconButton
                  aria-label="process"
                  onClick={() => handleReRun(params.row)}
                  sx={{ color: 'orange' }}
                >
                  <Sync />
                </IconButton>
              </Tooltip>
            )} */}
            {status === 3 && (
              <Tooltip title="Download">
                <IconButton
                  aria-label="download"
                  onClick={() => handleDownloadBase64(params.row)}
                  sx={{ color: 'green' }}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
            )}
            {(status !== 3 && status !== 1 && status === 2) && (
              <Tooltip title="Re-Run Job">
                <IconButton
                  aria-label="re-run-job"
                  onClick={() => handleReRun(params.row)}
                  sx={{ color: 'purple' }}
                >
                  <Replay />
                </IconButton>
              </Tooltip>
            )}
            {status !== 3 && status !==0 && (
              <Tooltip title="Total Records Processed">
                <IconButton
                  aria-label="refresh"
                  onClick={() => handleRefresh(params.row)}
                  sx={{ color: 'gray' }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
            )}
          </>
        );
      },
    },
  ];

  const buttonStyle = {
    width: `${buttonLabel.length * 15}px`, // Adjust multiplier as needed for ideal width
  };
  const handleProcessAllJobs = async (selectedRows) => {
    try {
  
      console.log('Selected rows getting:', selectedRows)
  
      toast("Processing the all jobs", {
        icon: <Info />,
        style: {
          borderRadius: '10px',
          background: '#0fc9ee',
          color: '#fff',
          width: '180px'
        },
      })

      const rowsArray = Array.isArray(selectedRows) ? selectedRows : [selectedRows];
     
      const formattedData = rowsArray.map((row) => ({
        id: row.id,
        fileid: row.fileid,
        fileurl: row.file_url, 
      }));
  
      console.log('Processing the following jobs:', formattedData);
      setIsProcessing(true);
      const response = await fetch(`${baseURI}job_analysis/businesstool/job_extractor`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: formattedData }),
      });
  

       
      if (response.ok) {
        const result = await response.json();
        toast.success('All jobs processed successfully!');
        setIsProcessing(true);
        console.log(result);
      } else {
        const errorData = await response.json();
        toast.error(`Failed to process jobs: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error processing jobs:', error);
      toast.error('An error occurred while processing jobs.');
    }
    
  }
  return (
    <>
      <div className='job-extractor'>
      <Toaster position="top-right" reverseOrder={false} />
        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
        {isCircleLoading ? (
        <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '70vh',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              zIndex: 10,
            }}
          >
            <CircularProgressWithLabel value={progress} />
            <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
              Loading...
            </Typography>
          </Box>
        ) : (
          <div className='jobextractor-table'>
            <Box>
              <CardContent style={{ paddingBottom: '10px' }}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                      <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                      <Box alignItems="center" className="dd" style={{display: 'flex'}}>
                        <div style={{marginRight: '15px'}}>
                          <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '16px' }}>* Click here to download the sample file for upload 
                            <a href='/dummy_data_file.xlsx' download style={{marginLeft: '15px'}}>
                                Click Here
                            </a>
                          </Typography>
                        </div>
                       
                        <TextField label="Search" variant="outlined" size="small" style={{ marginRight: '8px' }} />
                        
                        <Tooltip title="Upload File" arrow>
                          <Button
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{ marginLeft: '8px' }}
                            className='button-t button-j'
                            onClick={handleClickOpen}
                          >
                            Upload File
                          </Button>
                        </Tooltip>
                        {selectedRows.length > 0 && (
                           <Tooltip title="Process All Jobs" arrow>
                              <Button
                                variant="contained"
                                color="secondary"
                                className='button-r'
                                style={{ marginLeft: '15px' }}
                                onClick={() => handleProcessAllJobs(selectedRows)}
                              >
                                Process All Jobs
                              </Button>
                           </Tooltip>
                          
                        )}
                      </Box>
                    
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                  <DataGrid
                    rows={rows}
                    columns={columns}
                    checkboxSelection
                    isRowSelectable={(params) => params.row.status !== 3}
                    onRowSelectionModelChange={(newSelection) => {
                      handleSelectionChange(newSelection);
                    }}
                    className="job-extractor-table"
                    disableRowSelectionOnClick
                    slots={{ noRowsOverlay: NoRowsOverlay }}
                    slotProps={{
                      noRowsOverlay: {
                        sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                      },
                    }}
                    autoHeight
                  />
                  </Grid>
                </Grid>
              </CardContent>

              <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={(event, reason) => {
                  if (reason !== "backdropClick") handleClose();
                }}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="sm"
                className="job-extractor"
                disableEscapeKeyDown
              >
                <DialogTitle>
                  {"Job Extractor"}
                  <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-slide-description">
                    <Box id="drop-area" sx={{
                      padding: '20px',
                      textAlign: 'center',
                      borderRadius: '5px',
                      border: '2px dashed #ccc',
                      marginBottom: '20px'
                    }}>
                      <label htmlFor="file-upload">
                        <Input
                          accept=".xlsx,.csv"
                          id="file-upload"
                          type="file"
                          onChange={handleFileUpload}
                          multiple
                        />
                        <Button variant="contained" component="span">Upload File</Button>
                      </label>
                      <Typography sx={{ marginTop: '10px', color: '#666' }}>
                        {fileNames ? `Selected file: ${fileNames}` : 'No file selected'}
                      </Typography>
                    </Box>
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={sendDataToBackend} 
                  className={buttonClass}
                  type='submit' disabled={disableButton} 
                  style={{
                    ...buttonStyle,
                    backgroundColor: disableButton ? '#d3d3d3' : '#1976d2',
                    color: disableButton ? '#9e9e9e' : '#fff',
                  }}>{buttonLabel}</Button>
                </DialogActions>
              </Dialog>
            </Box>
          </div>
        )}
        </Card>
      </div>
    </>
  );
}

export default JobExtractor;
