import { Box, Card, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from 'react-router-dom';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import BusinessIcon from '@mui/icons-material/Business';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FactoryIcon from '@mui/icons-material/Factory';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import CampaignIcon from '@mui/icons-material/Campaign';

const Campaign = () => {

    const userRole = JSON.parse(localStorage.getItem("userdetails"));
    const userRoleId = userRole.user.roleId;

    const allowedRolesForToolbox = [1, 4];

    const getGridProps = (roleId) => {
        if ([2, 3, 5, 6].includes(roleId)) {
          return { xs: 12, sm: 12, md: 12, lg: 12 };
        } else {
          return { xs: 12, sm: 6, md: 6, lg: 4, xl: 4 }; 
        }
    };

    const getGridInnerProps = (roleId) => {
        if ([2, 3, 5, 6].includes(roleId)) {
          return { xs: 12, sm: 12, md: 6, lg: 4 };
        } else {
          return { xs: 12, sm: 12, md: 12, lg: 12 };  
        }
    };
    const gridProps = getGridProps(userRoleId);
    const gridInnerProps = getGridInnerProps(userRoleId);

    return (
        <Box component='div' className="campaign">
            <Grid
                container
                spacing={2}
                alignItems={"center"}
                sx={{ padding: "0px 0px 16px " }}
            >
                {allowedRolesForToolbox.includes(userRoleId) && (
                     <Grid item sm={6} lg={8} xl={8} md={6}>
                     <Box className="first-block">
                     <div className="tool-box-heading">
                         <Typography component='h6' className="heading">Tool Box</Typography>
                     </div>
                     
                     <Box component='div' className="tool-box">
                         <Grid
                             container
                             spacing={2}
                             alignItems={"center"}
                             sx={{ padding: "0px 0px 16px " }}
                         >
                             <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                             <Link to={'/campaign-dashboard/blocked-companies'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <BusinessIcon className="business-icon icon"/>
                                                     <div className="not-icon"><NotInterestedIcon className="notinterested-icon icon"/></div>
                                                     <div className="content">
                                                        Blocked Company urls
                                                     </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                 </Link>
                             </Grid>
                             <Grid item  xs={12} sm={12} md={12} lg={6} xl={4}>
                             <Link to={'/campaign-dashboard/company-names'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <BusinessIcon className="business-icon icon"/>
                                                     <div className="content">
                                                        Blocked Company Names
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                 </Link>
                             </Grid>
                             <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                             <Link to={'/campaign-dashboard/job-names'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <WorkOutlineIcon className="workout-icon icon"/>
                                                     <div className="content">
                                                         Blocked Job Names
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                 </Link>
                             </Grid>
                             <Grid xs={12} sm={12} md={12} item lg={6} xl={4}>
                             <Link to={'/campaign-dashboard/manage-user'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <ManageAccountsIcon className="Manage-icon icon"/>
                                                     <div className="content">
                                                         Manage Users
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                 </Link>
                             </Grid>
                             <Grid xs={12} sm={12} md={12} item lg={6} xl={4}>
                                <Link to={'/campaign-dashboard/seamless-email'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <ManageAccountsIcon className="Manage-icon icon"/>
                                                     <div className="content">
                                                         Seamless Email
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                </Link>
                             </Grid>
 
                             <Grid  xs={12} sm={12} md={12} item lg={6} xl={4}>
                                <Link to={'/campaign-dashboard/blocked-industries'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <FactoryIcon className="business-icon icon"/>
                                                     <div className="not-icon"><NotInterestedIcon className="notinterested-icon icon"/></div>
                                                     <div className="content">
                                                         Blocked Industries
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                </Link>
 
                             </Grid>
                             <Grid  xs={12} sm={12} md={12} item lg={6} xl={4}>
                             <Link to={'/campaign-dashboard/company-sizes'}>
                                <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <CorporateFareIcon className="Manage-icon icon"/>
                                                     <div className="content">
                                                        Blocked Company Sizes
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                </Link>
                             </Grid>
                             <Grid xs={12} sm={12} md={12} item lg={6} xl={4}>
                                 <Link to={'/campaign-dashboard/unsubscribed-email-size'}>
                                  <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <MailOutlineIcon className="business-icon icon"/>
                                                     <div className="remove-icon"><RemoveCircleOutlineIcon className="notinterested-icon icon"/></div>
                                                     <div className="content">
                                                         Unsubscribed Emails
                                                     </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                     </Card>
                                    </Link>
                             </Grid>
                             <Grid xs={12} sm={12} md={12} item lg={6} xl={4}>
                                <Link to={'/campaign-dashboard/view-sent-email'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <MarkEmailReadIcon className="Manage-icon icon"/>
                                                     <div className="content">
                                                        View Sent Emails
                                                 </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                </Link>
                             </Grid>
                             <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                                <Link to={'/campaign-dashboard/mail-templates'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <MailOutlineIcon className="business-icon icon"/>
                                                     
                                                     <div className="content">
                                                        Mail Templates
                                                     </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                </Link>
                             </Grid>
                             <Grid item xs={12} sm={12} md={12} lg={6} xl={4}>
                                <Link to={'/campaign-dashboard/job-templates'}>
                                 <Card className="card-1">
                                         <Box>
                                             <div className="blocked-content">
                                                 <div className="blocked_companies">
                                                     <WorkOutlineIcon className="business-icon icon"/>
                                                     
                                                     <div className="content">
                                                        Job Templates
                                                     </div>
                                                 </div>
                                                 
                                             </div>
                                         </Box>
                                 </Card>
                                </Link>
                             </Grid>
                          
                         </Grid>
                         
                     </Box>
                     </Box>
                    
                 </Grid>
                ) }
               
                <Grid item {...gridProps}>
                    <Box className="second-block">

                    <div className="tool-box-heading">
                        <Typography component='h6' className="heading">Functions</Typography>
                    </div>
                    
                    <Box component='div' className="functions">
                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            sx={{ padding: "0px 0px 16px " }}
                        >
                            <Grid item {...gridInnerProps}>
                               <Link to='/campaign-dashboard/job-extractor'>
                                <Card className="card-1">
                                        <Box>
                                            <div className="blocked-content">
                                                <div className="blocked_companies">
                                                    <ScreenSearchDesktopIcon className="Manage-icon icon"/>
                                                    <div className="content">
                                                        Job Extractor
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </Box>
                                </Card>
                                </Link>  
                               
                            </Grid>
                            <Grid item  {...gridInnerProps}>
                            <Link to={'/campaign-dashboard/seamless-ai'}>
                                    <Card className="card-1">
                                            <Box>
                                                <div className="blocked-content">
                                                    <div className="blocked_companies">
                                                        <CampaignIcon className="Manage-icon icon"/>
                                                        
                                                        <div className="content">
                                                            Seamless AI
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </Box>
                                    </Card>
                                </Link>
                                
                            </Grid>
                            <Grid item  {...gridInnerProps}>
                            <Link to='/campaign-dashboard/email-campaign' style={{ cursor: 'pointer', textDecoration: 'none' }}>
                                <Card className="card-1">
                                        <Box>
                                            <div className="blocked-content">
                                                <div className="blocked_companies">
                                                    <CampaignIcon className="Manage-icon icon"/>
                                                    <div className="content">
                                                        Email Campaign
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        </Box>
                                </Card>
                                </Link> 
                            </Grid>
                        </Grid>
                    </Box>
                    </Box>
                    
                </Grid>
            </Grid>
        </Box >
    )
}

export default Campaign;
