import { Button, Card, CardContent, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Info } from '@mui/icons-material';

const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const SeamlessEmailIn = () => {
    const [open, setOpen] = useState(false);
    const [rows, setRows] = useState([]);
    const [seamlessid, setSeamlessid] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredRows, setFilteredRows] = useState([]);
    const [selectedURLToDelete, setSelectedURLToDelete] = useState(false);
    const [deleteId, setDeleteId] = useState(null);
    const [progress, setProgress] = useState(0);
    const [isCircleLoading, setCircleIsLoading] = useState(false);
    const [ferrors, setErrors] = useState({});
    const [seamlessEmail, setSeamlessEmail] = useState('');
    const [isProgress, setIsProgress] = useState(false);
    const [seamlessPassword, setSeamlessPassword] = useState('');
    const [seamlessPasswordVisible, setSeamlessPasswordVisible] = useState(false);
    const [modelName, setModelName] = useState("add");
    const [creditRemaining, setCreditRemaining] = useState('');

    const validateFields = () => {
        const newErrors = {};

        if (!seamlessEmail || seamlessEmail.trim() === '') {
            newErrors.seamlessEmail = 'Seamless Email is required';
        } else if (!/\S+@\S+\.\S+/.test(seamlessEmail)) {
            newErrors.seamlessEmail = 'Enter a valid email';
        }

        if (!seamlessPassword || seamlessPassword.trim() === '') {
            newErrors.seamlessPassword = 'Seamless Password is required';
        } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=-]{6,20}$/.test(seamlessPassword)) {
            newErrors.seamlessPassword = 'Password must be at least 6 characters and can include letters, numbers, and symbols (!@#$%^&*()_+=-)';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleClickShowPassword = () => {
        setSeamlessPasswordVisible(!seamlessPasswordVisible);
    };

    const ClickOpen = (row) => {
        setOpen(true);
        setModelName("add")
        setSeamlessid('')
        setSeamlessEmail('');
        setSeamlessPassword('');
        setSelectedURLToDelete(false);
        setDeleteId(null);
    }

    const handleClose = () => {
        setOpen(false);
        setSeamlessid('')
        setSeamlessEmail('');
        setSeamlessPassword('');
        setSelectedURLToDelete(false);
        setDeleteId(null);
    };

    const fetchJobsData = async () => {
        setProgress(10)
        setCircleIsLoading(true)
        try {
            setProgress(40)
            setProgress(70)
            const response = await fetch(`${baseURI}job_analysis/businesstool/viewallseamless`,
                {
                    method: 'GET',
                    headers: { 'Content-Type': 'application/json' },
                }
            );

            if (response.ok) {
                const data = await response.json();
                setRows(data.details)
                setFilteredRows(data.details);
                setProgress(100)
                setCircleIsLoading(false)
            } else {
                console.error(`Error: ${response.status} - ${response.statusText}`);
            }
        } catch (error) {

        }
    }

    const handleRefresh = async (row) => {
        toast("Refreshing the table", {
            icon: <Info />,
            style: {
                borderRadius: '10px',
                background: '#ee9d0f',
                color: '#fff',
            },
        });

        const seamlessUserData = {
            seamlessid: row.seamlessid,
            email: row.email,
            password: row.password,
        };

        console.log(seamlessUserData, "seamlessUserData")

        try {
            const response = await fetch(
                `${baseURI}job_analysis/businesstool/checkseamless`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(seamlessUserData),
                }
            );

            if (response.ok) {
                const result = await response.json();
                console.log('check seamless  successful:', result);

                toast.success(result.message, {
                    style: {
                        borderRadius: '10px',
                        background: '#28a745',
                        color: '#fff',
                    },
                });

                fetchJobsData();
            } else {
                const errorDetails = await response.json();
                console.error('Error refreshing row:', errorDetails);

                toast.error(`Error: ${errorDetails.message || response.statusText}`, {
                    style: {
                        borderRadius: '10px',
                        background: '#dc3545',
                        color: '#fff',
                    },
                });
            }
        } catch (error) {
            console.error('Error in handleRefresh:', error);

            toast.error('An unexpected error occurred.', {
                style: {
                    borderRadius: '10px',
                    background: '#dc3545',
                    color: '#fff',
                },
            });
        }
    };

    useEffect(() => {
        fetchJobsData()
    }, [])

    const handleDeleteClick = (row) => {
        console.log(row);
        setSelectedURLToDelete(true);
        setDeleteId(row.seamlessid);
        setOpen(true);
    };

    const handleEdit = async (row, modelName) => {
        setSeamlessid(row.seamlessid);
        console.log(modelName)
        if (row.seamlessid && modelName === "Edit") {
            setIsProgress(true)
            setSeamlessEmail(row.email || "");
            setSeamlessPassword(row.password || "");
            setCreditRemaining(row.credit_remaining)
            setModelName("edit");
            setOpen(true);
            setIsProgress(false)

        } else if (modelName === "Add") {
            ClickOpen(row)
        } else {
            toast.error("Add user details before edit.")
        }
    };

    const handleSubmit = async () => {

        setIsProgress(true)
        console.log(modelName)
        if (selectedURLToDelete || modelName === "delete") {
            console.log("delete")
            try {
                const response = await fetch(`${baseURI}job_analysis/businesstool/delseamless`, {
                    method: 'DELETE',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ seamlessid: deleteId }),
                });
                if (response.ok) {
                    toast.success('Record deleted successfully.');
                    handleClose();
                    fetchJobsData();
                } else {
                    toast.error('Failed to delete the record.');
                }
            } catch (error) {
                toast.error('An error occurred while deleting the record.');
            }
        }
        else if (modelName === "edit") {
            if (!validateFields()) {
                return;
            }
            const updatedRequestBody = {
                seamlessid,
                email: seamlessEmail,
                password: seamlessPassword,
                credit_remaining: creditRemaining
            };

            console.log(updatedRequestBody, "updatedRequestBody");

            try {
                const response = await fetch(`${baseURI}job_analysis/businesstool/editseamless`, {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(updatedRequestBody),
                });
                if (response.status === 200) {
                    toast.success('Record updated successfully.');
                    handleClose();
                    fetchJobsData();
                    setIsProgress(false)
                } else {
                    toast.error('Failed to update the record.');
                }
            } catch (error) {
                toast.error('An error occurred while updating the record.');
            }
        }
        else {
            if (!validateFields()) {
                setIsProgress(false)
                return
            }

            const seamlessUserData = {
                seamlessid: '',
                email: seamlessEmail,
                password: seamlessPassword,
            };

            console.log(seamlessUserData, "seamlessUserData")

            try {
                const response = await fetch(`${baseURI}job_analysis/businesstool/addseamless`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(seamlessUserData),
                });
                if (response.ok) {
                    const responseData = await response.json();
                    console.log(responseData)
                    toast.success(responseData.message);
                    handleClose();
                    fetchJobsData();
                } else {
                    toast.error('Failed to add the record.');
                }
            } catch (error) {
                toast.error('An error occurred while adding the record.');
            }
        }
    };



    const NoRowsOverlay = () => (
        <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}> No records </Box>
    );

    // Data Columns
    const columns = [
        {
            field: 'seamlessid', headerName: 'Seamless Id', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => {

                const fileId = params.value;
                const lastSixDigits = fileId.slice(-6);
                return <span>{lastSixDigits}</span>;
            }
        },
        {
            field: 'email', headerName: 'Seamless Email', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
                <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.value}
                    </div>
                </Tooltip>
            )
        },
        {
            field: 'contact_credit_remaining', headerName: 'Contact Credit ', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
                <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.value}
                    </div>
                </Tooltip>
            )
        },
        {
            field: 'search_credit_remaining', headerName: 'Search Credit ', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
                <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
                    <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                        {params.value}
                    </div>
                </Tooltip>
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            flex: 3,
            renderCell: (params) => (
                <>
                    <Tooltip title="Delete" arrow>
                        <IconButton
                            size="small"
                            onClick={() => handleDeleteClick(params.row)}
                            className='button-a'
                        >
                            <DeleteIcon color="primary" />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title="Edit User" arrow>
                        <IconButton
                            size="small"
                            onClick={() => handleEdit(params.row, "Edit")}
                            className='button-a'
                        >
                            <EditIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Refresh " arrow>
                        <IconButton
                            size="small"
                            onClick={() => handleRefresh(params.row)}
                            className='button-a'
                        >
                            <RefreshIcon color="primary" />
                        </IconButton>
                    </Tooltip>
                </>
            ),
            headerClassName: 'bg-primary text-white text-size',
        },
    ];



    const handleSearchChange = (e) => {
        const value = e.target.value.toLowerCase();
        setSearchTerm(value);
        const filtered = rows.filter((row) =>
            row.email.toLowerCase().includes(value)
        );
        setFilteredRows(filtered);
    };

    return (
        <>
            <div className='blocked-companies'>
                <Toaster position="top-right" reverseOrder={false} />
                <Card variant="outlined" sx={{ borderRadius: '10px' }}>
                    {isCircleLoading ? (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '70vh',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                zIndex: 10,
                            }}
                        >
                            <CircularProgressWithLabel value={progress} />
                            <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
                                Loading...
                            </Typography>
                        </Box>
                    ) : (
                        <div className='jobextractor-table'>
                            <Box>
                                <CardContent style={{ paddingBottom: '10px' }}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                                                <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                                                <Box alignItems="center" className="dd" style={{ display: 'flex' }}>

                                                    <TextField
                                                        label="Search"
                                                        variant="outlined"
                                                        size="small"
                                                        style={{ marginRight: '8px' }}
                                                        value={searchTerm}
                                                        onChange={handleSearchChange}
                                                    />

                                                    <Tooltip title="Add Seamless Email" arrow>
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            size="small"
                                                            style={{ marginLeft: '8px', width: '250px' }}
                                                            className='button-t button-j'
                                                            onClick={ClickOpen}
                                                        >
                                                            Add Seamless Email
                                                        </Button>
                                                    </Tooltip>
                                                </Box>

                                            </Box>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <DataGrid
                                                rows={filteredRows}
                                                columns={columns}
                                                getRowId={(row) => row.seamlessid}
                                                className="job-extractor-table"
                                                disableRowSelectionOnClick
                                                slots={{ noRowsOverlay: NoRowsOverlay }}
                                                slotProps={{
                                                    noRowsOverlay: {
                                                        sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                                                    },
                                                }}
                                                initialState={{
                                                    pagination: {
                                                        paginationModel: { pageSize: 10, page: 0 },
                                                    },
                                                }}
                                                pageSizeOptions={[10, 25, { value: -1, label: 'All' }]}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <Dialog
                                    open={open}
                                    TransitionComponent={Transition}
                                    keepMounted
                                    onClose={(event, reason) => {
                                        if (reason !== "backdropClick") handleClose();
                                    }}
                                    aria-describedby="alert-dialog-slide-description"
                                    fullWidth
                                    maxWidth="sm"
                                    className="job-extractor"
                                    disableEscapeKeyDown
                                    sx={{ padding: 0 }}
                                >
                                    <DialogTitle>
                                        <Typography variant='h6' className='dialog-title bottom-line'>  {selectedURLToDelete ? 'Delete Confirmation' : 'Seamless Email'}</Typography>
                                        <IconButton
                                            aria-label="close"
                                            onClick={handleClose}
                                            sx={{ position: 'absolute', right: 8, top: 8 }}
                                            className='bg-primary'
                                        >
                                            <CloseIcon />
                                        </IconButton>
                                    </DialogTitle>
                                    <DialogContent>
                                        {selectedURLToDelete ? (
                                            <DialogContentText id="alert-dialog-slide-description">
                                                Are you sure you want to delete this record?
                                                <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={handleSubmit}
                                                        sx={{ marginRight: '8px' }}
                                                    >
                                                        Confirm
                                                    </Button>
                                                    <Button variant="contained" color='error' onClick={handleClose}>
                                                        Cancel
                                                    </Button>
                                                </Box>
                                            </DialogContentText>
                                        ) : (
                                            <DialogContentText id="alert-dialog-slide-description">
                                                <Box id="drop-area"
                                                    sx={{
                                                        padding: '20px',
                                                        textAlign: 'center',
                                                        borderRadius: '5px',
                                                        marginBottom: '20px'
                                                    }}>
                                                    <Box sx={{ marginBottom: '10px' }}>
                                                        {/* <TextField
                                                            type='text'
                                                            label='Add company to block'
                                                            placeholder='Add company to block'
                                                            fullWidth
                                                            value={url}
                                                            onChange={handleOnChange}
                                                        >
                                                        </TextField> */}
                                                        <Box
                                                            style={{ marginBottem: '15px' }}
                                                        >   <fieldset>
                                                                <legend>Seamless Credentials</legend>

                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    alignItems={"center"}
                                                                    style={{ padding: '0px' }}
                                                                >
                                                                    <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
                                                                        <TextField
                                                                            size='small'
                                                                            margin="normal"
                                                                            fullWidth
                                                                            type="text"
                                                                            label="Seamless Email"
                                                                            placeholder='Seamless Email'
                                                                            value={seamlessEmail}
                                                                            onChange={(e) => setSeamlessEmail(e.target.value)}
                                                                            sx={{ width: "100%" }}
                                                                            error={!!ferrors.seamlessEmail}
                                                                            helperText={ferrors.seamlessEmail}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
                                                                        <TextField
                                                                            size='small'
                                                                            margin="normal"
                                                                            fullWidth
                                                                            type={seamlessPasswordVisible ? "text" : "password"}
                                                                            label="Seamless Password"
                                                                            placeholder='Seamless Password'
                                                                            value={seamlessPassword}
                                                                            onChange={(e) => setSeamlessPassword(e.target.value)}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end" >
                                                                                        <IconButton
                                                                                            aria-label="toggle password visibility"
                                                                                            onClick={handleClickShowPassword}
                                                                                            edge="end"
                                                                                            className='password'
                                                                                            sx={{ color: '#1976d2 !important' }}
                                                                                        >
                                                                                            {seamlessPasswordVisible ? <VisibilityOff style={{ color: '#333' }} /> : <Visibility style={{ color: '#333' }} />}
                                                                                        </IconButton>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                            sx={{ width: "100%" }}
                                                                            error={!!ferrors.seamlessPassword}
                                                                            helperText={ferrors.seamlessPassword}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </fieldset>
                                                        </Box>
                                                    </Box>
                                                    <Box>
                                                        <label htmlFor="file-upload">
                                                            <Button variant="contained" component="span" onClick={handleSubmit}>{
                                                                modelName === "edit"
                                                                    ? "Update User Details"
                                                                    : "Submit User Details"}
                                                            </Button>
                                                        </label>
                                                    </Box>
                                                </Box>
                                            </DialogContentText>
                                        )}
                                    </DialogContent>
                                </Dialog>

                            </Box>
                        </div>
                    )}
                </Card>
            </div>
        </>
    );
}

export default SeamlessEmailIn;
