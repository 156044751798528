import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import SeamlessEmailIn from './seamless'

const SeamlessEmail = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Seamless Email' data={[{ title: 'Campaign Dashboard' }]}/>
            <div>
                <SeamlessEmailIn />
            </div>
        </Fragment>
    )
}

export default SeamlessEmail;
