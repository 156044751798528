import React from "react";
import { Quill } from "react-quill";
const Inline = Quill.import("blots/inline");

class LineHeightBlot extends Quill.import("blots/block") {
  static create(value) {
    const node = super.create();
    node.style.lineHeight = value;
    return node;
  }

  static formats(node) {
    return node.style.lineHeight || false;
  }

  format(name, value) {
    if (name === "lineheight" && value) {
      this.domNode.style.lineHeight = value; 
    } else {
      super.format(name, value);
    }
  }
}

LineHeightBlot.blotName = "lineheight";
LineHeightBlot.tagName = "p";
Quill.register(LineHeightBlot, true);
// const CustomUndo = () => (
//   <svg viewBox="0 0 18 18">
//     <polygon className="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10" />
//     <path
//       className="ql-stroke"
//       d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"
//     />
//   </svg>
// );


// const CustomRedo = () => (
//   <svg viewBox="0 0 18 18">
//     <polygon className="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10" />
//     <path
//       className="ql-stroke"
//       d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"
//     />
//   </svg>
// );


// function undoChange() {
//   this.quill.history.undo();
// }
// function redoChange() {
//   this.quill.history.redo();
// }


const Size = Quill.import("parchment").Attributor.Style;
const SizeStyle = new Size("size", "font-size", {
  scope: Quill.import("parchment").Scope.INLINE,
  whitelist: [
    "5px",
    "8px",
    "10px", 
    "12px", 
    "14px",
    "16px",
    "18px",
    "20px",
    "25px",
    "30px",
    "35px",
  ],
});
Quill.register(SizeStyle, true);

//Align add inline
const AlignClass = Quill.import("parchment");
const AlignStyle = new AlignClass.Attributor.Style("align", "text-align", {
  scope: AlignClass.Scope.BLOCK,
});

Quill.register(AlignStyle, true);


//Indent
const IndentPara = Quill.import("parchment");
const PaddingStyle = new IndentPara.Attributor.Style("padding-left", "padding-left", {
  scope: IndentPara.Scope.BLOCK,
});

Quill.register(PaddingStyle, true);
const Font = Quill.import("parchment").Attributor.Style;
const FontStyle = new Font("font", "font-family", {
  scope: Quill.import("parchment").Scope.INLINE,
  whitelist: [
    "Sans Serif, sans-serif",
    "Serif, serif",
    "Monospace, monospace",
    "Arial, Helvetica, sans-serif",
    "Times New Roman, Times, serif",
    "Calibri",
    "Verdana, Geneva, sans-serif",
  ],
});
Quill.register(FontStyle, true);


const customCSS = `
.ql-editor{height: 220px !important}
.ql-editor p {
  margin-top: 10px;
  margin-bottom: 10px
}
.ql-font-sans-serif { font-family: "Sans Serif", sans-serif; }
.ql-font-serif { font-family: "Serif", serif; }
.ql-font-monospace { font-family: "Monospace", monospace; }
.ql-font-arial { font-family: "Arial", Helvetica, sans-serif; }
.ql-font-times-new-roman { font-family: "Times New Roman", Times, serif; }
.ql-font-calibri { font-family: Calibri, sans-serif; }
.ql-font-verdana { font-family: Verdana, Geneva, sans-serif; }
.ql-lineheight { line-height: inherit; }
.ql-snow .ql-picker.ql-lineheight{
    width: 58px;
}
    .ql-snow span.ql-picker[aria-label="Font Family"]{
        width: 150px
    }
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.0']::before {content: '1.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.0']::before {content: '1.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.2']::before {content: '1.2';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.2']::before {content: '1.2' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.5']::before {content: '1.5';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.5']::before {content: '1.5' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.6']::before {content: '1.6';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.6']::before {content: '1.6' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='1.8']::before {content: '1.8';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='1.8']::before {content: '1.8' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.0']::before {content: '2.0';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.0']::before {content: '2.0' !important;}
.ql-snow .ql-picker.ql-lineheight .ql-picker-item[data-value='2.4']::before {content: '2.4';}
.ql-snow .ql-picker.ql-lineheight .ql-picker-label[data-value='2.4']::before {content: '2.4' !important;}
.ql-editor span[line-height="1.0"] {
        line-height: 1.0;
    }
    .ql-editor p span[line-height="1.2"] {
        line-height: 1.2;
    }
    .ql-editor p span[line-height="1.5"] {
        line-height: 1.5;
    }
    .ql-editor p span[line-height="1.6"] {
        line-height: 1.6;
    }
    .ql-editor p span[line-height="1.8"] {
        line-height: 1.8;
    }
    .ql-editor p span[line-height="2.0"] {
        line-height: 2.0;
    }
    .ql-editor p span[line-height="2.4"] {
        line-height: 2.4;
    }
    .ql-editor p span[line-height="2.8"] {
        line-height: 2.8;
    }
        .ql-snow .ql-picker.ql-header{
        width: 50px !important
        }
        .ql-snow .ql-picker.ql-lineheight {
  width: 70px;
}
  .ql-snow .ql-picker.ql-lineheight .ql-picker-item::before {
  content: attr(data-value); /* Use the data-value attribute as content */
  display: inline-block;
  text-align: center;
  width: 100%;
}

.ql-snow .ql-picker.ql-lineheight .ql-picker-label::before {
  content: attr(data-value); /* Show selected value */
  display: inline-block;
  text-align: center;
  width: 100%;
}

.ql-snow .ql-picker-label {
    padding-left: 0px !important;
    padding-right: 8px !important;;
}
    .ql-size-10px {
  font-size: 10px;
}
.ql-size-12px {
  font-size: 12px;
}
.ql-size-14px {
  font-size: 14px;
}
.ql-size-18px {
  font-size: 18px;
}
.ql-size-32px {
  font-size: 32px;
}
    
        `
    
   
;

const style = document.createElement("style");
style.innerHTML = customCSS;
document.head.appendChild(style);

export const modules = {
  toolbar: {
    container: "#toolbar",
    handlers: {
    //   undo: undoChange,
    //   redo: redoChange
    lineheight: function (value) {
      const range = this.quill.getSelection();
      if (range) {
        this.quill.formatLine(range.index, range.length, "lineheight", value || false);
      }
    },
      indent: function (value) {
        const range = this.quill.getSelection();
        if (!range) return;

        const [line] = this.quill.getLine(range.index);
        if (!line || !line.domNode) {
          console.error("Line or DOM node not found");
          return;
        }

        const currentIndent = parseFloat(line.domNode.style.paddingLeft) || 0;

        let newIndent;
        if (value === "+1") {
          newIndent = currentIndent + 3;
        } else if (value === "-1") {
          newIndent = currentIndent - 3; 
          newIndent = Math.max(newIndent, 0); 
        }
        if (newIndent > 0) {
          line.domNode.style.paddingLeft = `${newIndent}em`;
          console.log(`Applied paddingLeft: ${line.domNode.style.paddingLeft}`);
        } else {
          line.domNode.style.paddingLeft = "";
          console.log("Removed paddingLeft");
        }
      },
    },
    keyboard: {
      bindings: {
        backspace: {
          key: 8,
          collapsed: true,
          handler: function (range, context) {
            const [line] = this.quill.getLine(range.index);
            if (
              line &&
              line.domNode.tagName === "P" &&
              line.domNode.getAttribute("line-height")
            ) {
              line.domNode.removeAttribute("line-height");
            }
            return true;
          },
        },
      },
    },
  },
  history: {
    delay: 500,
    maxStack: 100,
    userOnly: true
  }
};


export const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "align",
//   "strike",
  "blockquote",
  "background",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "color",
  "lineheight",
  "padding-left",
//   "code-block"
];


export const QuillToolbar = () => (
  <div id="toolbar">
    <span className="ql-formats">
    <select className="ql-font" defaultValue="arial" aria-label="Font Family" style={{width: '150px'}}>
        <option value="Sans Serif, sans-serif">Sans Serif</option>
        <option value="Serif, serif">Serif</option>
        <option value="Monospace, monospace">Monospace</option>
        <option value="Arial, Helvetica, sans-serif">Arial</option>
        <option value="Times New Roman, Times, serif">Times New Roman</option>
        <option value="Calibri">Calibri</option>
        <option value="Verdana, Geneva, sans-serif">Verdana</option>
    </select>
    <select className="ql-size" defaultValue="12px">
      <option value="5px">5px</option>
      <option value="8px">8px</option>
      <option value="10px">10px</option>
      <option value="12px">12px</option>
      <option value="14px">14px</option>
      <option value="16px">16px</option>
      <option value="18px">18px</option>
      <option value="20px">20px</option>
      <option value="25px">25px</option>
      <option value="30px">30px</option>
      <option value="35px">35px</option>
    </select>
      <select className="ql-header" defaultValue="4">
        <option value="1">H1</option>
        <option value="2">H2</option>
        <option value="3">H3</option>
        <option value="4">P</option>
      </select>
    </span>
    <span className="ql-formats">
      <button className="ql-bold" />
      <button className="ql-italic" />
      <button className="ql-underline" />
      {/* <button className="ql-strike" /> */}
    </span>
    <span className="ql-formats">
      <button className="ql-list" value="ordered" />
      <button className="ql-list" value="bullet" />
      <button className="ql-indent" value="-1" />
      <button className="ql-indent" value="+1" />
    </span>
    <span className="ql-formats" style={{marginRight: '10px'}}>
      <button className="ql-blockquote" />
    </span>
    <span className="ql-formats" style={{marginRight: '10px'}}>
      <select className="ql-align">
        {/* <option value="" />
        <option value="left" />
        <option value="center" />
        <option value="right" />
        <option value="justify" /> */}
      </select>
      <select className="ql-color" />
      <select className="ql-background" />
    </span>
    <span className="ql-formats" style={{marginRight: '10px'}}>
      <button className="ql-link" />
      <button className="ql-image" />
      {/* <button className="ql-video" /> */}
    </span>
    {/* <span className="ql-formats"> */}
      {/* <button className="ql-formula" /> */}
      {/* <button className="ql-code-block" /> */}
      {/* <button className="ql-clean" /> */}
    {/* </span> */}
    {/* <span className="ql-formats">
      <button className="ql-undo">
        <CustomUndo />
      </button>
      <button className="ql-redo">
        <CustomRedo />
      </button>
    </span> */}
    <span className="ql-formats" style={{marginRight: '10px',marginLeft: '0px', width: '58px' }}>
        <select class="ql-lineheight" defaultValue="1.0em">
            <option value="0.5em" data-value="0.5em">0.5</option>
            <option value="0.8em" data-value="0.8em">0.8</option>
            <option value="1.0em" data-value="1.0em">1</option>
            <option value="1.1em" data-value="1.1em">1.1</option>
            <option value="1.2em" data-value="1.2em">1.2</option>
            <option value="1.3em" data-value="1.3em">1.3</option>
            <option value="1.4em" data-value="1.4em">1.4</option>
            <option value="1.5em" data-value="1.5em">1.5</option>
            <option value="1.6em" data-value="1.6em">1.6</option>
            <option value="1.8em" data-value="1.8em">1.8</option>
            <option value="2em" data-value="2em">2</option>
            <option value="2.1em" data-value="2.1em">2.1</option>
            <option value="2.2em" data-value="2.2em">2.2</option>
            <option value="2.4em" data-value="2.4em">2.4</option>
            <option value="2.5em" data-value="2.5em">2.5</option>
            <option value="2.6em" data-value="2.6em">2.6</option>
            <option value="2.7em" data-value="2.7em">2.7</option>
            <option value="2.8em" data-value="2.8em">2.8</option>
            <option value="2.9em" data-value="2.9em">2.9</option>
            <option value="3em" data-value="3em">3</option>
        </select>
    </span>
  </div>
);

export default QuillToolbar;
