import { Button, Card, CardContent, Grid, IconButton, ListItemText, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';
import OutlinedInput from '@mui/material/OutlinedInput';
import CustomDropdown from '../../components/reusableComponents/customDropdownRadio';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { CloudDownload, PlayArrow, Refresh, Info } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import axios from 'axios';
import { use } from 'react';
const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;
const Input = styled('input')({
  display: 'none',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const EmailCampaign = () => {
  const [open, setOpen] = useState(false);
  const [openToDetele, setopenToDetele] = useState(false);
  const [openEmailTemp, setOpenEmailTemp] = useState(false);
  const [content, setContent] = useState('');
  const [userCampId, setuserCampId] = useState(null);
  const [fileNames, setFileNames] = useState([]);
  const [emailDomains, setemailDomains] = useState([]);
  const [buttonLabel, setButtonLabel] = useState('Validate File');
  const [rows, setRows] = useState([]);
  const [selectedTempValue, setselectedTempValue] = useState(null)
  const [emailDomainsData, setemailDomainsData] = useState('');
  const [disableButton, setDisableButton] = useState(false);
  const [disableRunButton, setDisableRunButton] = useState(false);
  const [buttonClass, setButtonClass] = useState('custom-button');
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [personEmail, setPersonEmail] = useState([]);
  const [emailTemplates, setemailTemplates] = useState([]);
  const [isactive, setisActive] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [usersValues, setUsersValues] = useState('')
  const [data, setData] = useState(null);
  const [errors, setError] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [rowStatus, setRowStatus] = useState({});
  const rowsRef = useRef([]);
  const intervalIdRef = useRef(null);
  const handleClickOpen = () => {
    setOpen(true);
    setDisableButton(false)
    setDisableRunButton(false)
  };
  const handledeleteclose = () => {
    setopenToDetele(false);
  }

  const handleClose = () => {

    setOpen(false);
    setOpenEmailTemp(false);
    setContent('');
    setFileNames([]);
    setemailDomains([]);
    setButtonLabel('Validate File');
    setOpen(false);

    const fileInput = document.getElementById('file-upload');
    if (fileInput) {
      fileInput.value = '';
    }
  };


  const userDetails = JSON.parse(localStorage.getItem("userdetails"));
  const username = userDetails.user.name
  const userid = userDetails.user.id
  const roleId = userDetails.user.roleId


  const fetchJobsData = async () => {
    try {
      const response = await fetch(`${baseURI}job_analysis/businesstool/get_all_email_campaigns?userid=${userid}`, {
        method: 'GET',
      });

      if (response.ok) {
       
        const data = await response.json();
        const currentDate = new Date();
        const disabledRows = JSON.parse(localStorage.getItem('disabledRows')) || {};
        const processedRows = data.details.map((row) => {
          const lastDisabledTime = disabledRows[row.fileid];
          const lastRunDate = row.last_run_datetime
          ? new Date(row.last_run_datetime.replace(" ", "T"))
          : null;
          const isDisabledByLocalStorage = lastDisabledTime
          ? (currentDate - new Date(lastDisabledTime)) / (1000 * 3600 * 24) < 12
          : false;

        const isDisabledByLastRunDate = lastRunDate
          ? (currentDate - lastRunDate) / (1000 * 3600 * 24) < 12
          : false;
        
        const isPlayDisabled = isDisabledByLocalStorage || isDisabledByLastRunDate;

          return { ...row, isPlayDisabled };
        });

        setRows(processedRows);
        rowsRef.current = processedRows;
        const updatedDisabledRows = Object.keys(disabledRows).reduce((acc, fileId) => {
          const timestamp = disabledRows[fileId];
          if ((currentDate - new Date(timestamp)) / (1000 * 3600 * 24) < 12) {
            acc[fileId] = timestamp;
          }
          return acc;
        }, {});
  
        localStorage.setItem('disabledRows', JSON.stringify(updatedDisabledRows));
        
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error('fetching the error users', error);
    }
  };

  useEffect(() => {
    fetchJobsData();
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      rowsRef.current.forEach((row) => {
        if (
          (row.db_validation_status >= 1 && row.db_validation_status < 3) ||
          (row.data_prepare_status >= 1 && row.data_prepare_status < 3) ||
          (row.email_process_status >= 1 && row.email_process_status < 3)
        ) {
          handleRefresh(row);
        }
      });
    }, 2 * 60 * 1000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);


  const handleDownloadBase64 = async (row) => {
    try {


      const response = await fetch(`${baseURI}job_analysis/businesstool/ecfn_download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileid: row.fileid }),
      });
      fetchJobsData();
      if (response.ok) {
        const result = await response.json();
        toast.success('Job data retrieved successfully!');

        const base64Data = result.base64;
        const byteCharacters = atob(base64Data);
        const byteNumbers = Array.from(byteCharacters, char => char.charCodeAt(0));
        const byteArray = new Uint8Array(byteNumbers);

        const blob = new Blob([byteArray], { type: 'text/csv' });
        const downloadUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = `email_campaign_${row.fileid}.csv`;
        link.click();

        URL.revokeObjectURL(downloadUrl);

        toast.success('CSV downloaded successfully.');
      } else {
        const errorData = await response.json();
        toast.error(`Failed to retrieve job: ${errorData.message}`);
      }
    } catch (error) {
      console.error('Error processing jobs:', error);
      toast.error('An error occurred while processing jobs.');
    }
  };

  useEffect(() => {
    if (!isProcessing) return;

    let pollingInterval;

    const pollJobsData = async () => {
      await fetchJobsData();
      const processingJobs = rows.some((row) => row.status === 2);
      if (!processingJobs) {
        clearInterval(pollingInterval);
        setIsProcessing(false);
        toast.success("All jobs are now completed or idle.");
      }
    };

    pollingInterval = setInterval(pollJobsData, 5000);

    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [isProcessing, rows]);

  const handleSelect = (selectedOption) => {
    setselectedTempValue(selectedOption);

  };

  const sendDataToBackend = async () => {
    setProgress(10);
    setCircleIsLoading(true)
    try {
      setProgress(30);

      const docList = fileNames.map((name, index) => ({
        filename: name,
        base64: content[index],
      }));

      const response = await fetch(`${baseURI}job_analysis/businesstool/ec_file_upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userid, username, doc_list: docList }),
      });
      setProgress(40);

      if (!response.ok) {
        if (response.status === 500) {
          toast.error(response.statusText);
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        setProgress(50);
        toast.success('File uploaded successfully!');


        fetchJobsData()

        setTimeout(() => {
          setProgress(70);
          setTimeout(() => {
            setProgress(100);
            setTimeout(() => {
              setCircleIsLoading(false);
            }, 300);
          }, 500);
        }, 800);

      }

      handleClose();
    } catch (error) {
      console.log(error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleFileUpload = (event) => {
    setDisableButton(false)
    const files = event.target.files; // Get all selected files

    if (files.length === 0) {
      toast.error("No file selected");
      return;
    }

    setFileNames(Array.from(files).map((file) => file.name));

    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    Array.from(files).forEach((file) => {
      if (file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const rawData = XLSX.utils.sheet_to_json(sheet, { raw: true, defval: null });

          const filteredData = rawData.map((row) => {
            const cleanedRow = {};
            Object.keys(row).forEach((key) => {
              if (!key.startsWith("__EMPTY")) {
                cleanedRow[key] = row[key];
              }
            });
            return cleanedRow;
          });

          // if (filteredData.length > 200) {
          //   setDisableButton(true);
          //   setButtonClass('custom-button custom-disabled');
          //   toast.error(`Each file should only contain 200 records. More than that the file will not process`);
          //   return;
          // } else {
          //   setDisableButton(false);
          //   setButtonClass('button-t button-j');
          // }

          const base64String = await toBase64(file);
          if (base64String) {
            setDisableButton(true)
          } else {
            setDisableButton(false)
          }
          setContent((prevContent) => [...prevContent, base64String]); // Store multiple files' content
          toast.success(`File uploaded and parsed successfully!`);
        };

        reader.readAsArrayBuffer(file);
      } else if (file.name.endsWith('.csv')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvContent = e.target.result;
          const rows = csvContent
            .split('\n')
            .filter((row) => row.trim() !== '') // Remove empty rows
            .map((row) => row.split(',')); // Split CSV content into arrays

          // if (rows.length > 200) {
          //   setDisableButton(true);
          //   setButtonClass('custom-button custom-disabled');
          //   toast.error(`Each file should only contain 200 records. More than that the file will not process.`);
          //   return;
          // } else {
          //   setDisableButton(false);
          //   setButtonClass('button-t button-j');
          // }

          const headers = rows[0]; // First row as headers
          const data = rows.slice(1).map((row) =>
            headers.reduce((acc, header, index) => {
              acc[header] = row[index] || null;
              return acc;
            }, {})
          );

          const base64String = await toBase64(file);
          if (base64String) {
            setDisableButton(true)
          } else {
            setDisableButton(false)
          }
          
          setContent((prevContent) => [...prevContent, base64String]); // Store multiple files' content
          toast.success(`File uploaded and parsed successfully!`);
        };

        reader.readAsText(file);
      } else {
        toast.error(`Invalid file type it must be .xlsx or .csv file.`);
      }
    });
  };

  const handleReRun = async () => {
    setisActive(true)
    const currentTimestamp = new Date().toISOString();

    const userDetailsResponse = await fetch(
      `${baseURI}job_analysis/businesstool/get_spc_ec_filestatus?emailcampaignid=${rowData.emailcampaignid}`,
      {
        method: 'GET',
      }
    );

    const userDetails = await userDetailsResponse.json();

    const formattedRequestData = {
      userid: rowData.userid,
      username: rowData.username,
      office_position: data.office_position,
      phone_number: data.phone_number,
      email_domains: personEmail,
      email_template: selectedTempValue,
      fileurllist: [userDetails.details.file_url],
    };

    toast("Processing the following job", {
      icon: <Info />,
      style: {
        borderRadius: '10px',
        background: '#0fc9ee',
        color: '#fff',
      },
    })
    if (personEmail == '' || selectedTempValue == '') {
      toast.error("Please select at least one email domain and email template")
    }

    fetchJobsData();
    setIsProcessing(true)
    const disabledRows = JSON.parse(localStorage.getItem('disabledRows')) || {};
    disabledRows[rowData.fileid] = currentTimestamp;
    localStorage.setItem('disabledRows', JSON.stringify(disabledRows));
    try {
      handleClose()
      const response = await fetch(`${baseURI}job_analysis/businesstool/email_campaign`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formattedRequestData),
      });
    
      if (response.ok) {
        setisActive(false)
        const result = await response.json();
        
        setIsProcessing(true);
        toast.success(result.message);
        
        setRows((prevRows) =>
        prevRows.map((row) =>
          row.fileid === rowData.fileid
            ? { ...row, last_run_datetime: new Date().toISOString(), isPlayDisabled: true }
            : row
        )
      );
      } else {

        const errorData = await response.json();
        if (response.status === 500) {
          console.log('Error message:', errorData.exception_message);
          toast.error(`Error: ${errorData.exception_message}`, {
            style: {
              borderRadius: '10px',
              background: '#dc3545',
              color: '#fff',
              width: 'auto',
              maxWidth: '600px',
              padding: '10px 15px',
            },
          });
        } else {
          console.error(`Unexpected error: ${response.status}`);
          toast.error(`Unexpected error: ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error('Error re-running job:', error);
    }

  }

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}>No records</Box>
  );

  const handleOpenEmailTemp = async (row) => {
    setOpenEmailTemp(true)
    const userid = row.userid
    setuserCampId(userid)
    setRowData(row)
    setRowStatus((prev) => ({ ...prev, [row.fileid]: true }));
    setDisableRunButton(false)
    try {
      const response = await axios.post(`${baseURI}job_analysis/businesstool/ec_get_details`, { userid: row.userid });

      setData(response.data);
      setemailTemplates(response.data.email_templates)
      setemailDomains(response.data.email_domains)
      setDisableRunButton(false)
      
    } catch (error) {
      setError(error);
      // toast.error("Error fetching data:", er);
    }

  }

  const handleDelete = (row) => {
    setDeleteId(row.emailcampaignid);
    setopenToDetele(true);

  }

  const handleConfirmDelete = async () => {

    try {
      const response = await fetch(`${baseURI}job_analysis/businesstool/del_ec_campaign`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ emailcampaignid: deleteId }),
      });
      if (response.ok) {
        toast.success('Record deleted successfully.');
        handledeleteclose();
        fetchJobsData();
        setProgress(100)
        setCircleIsLoading(false)
      } else {
        toast.error('Failed to delete the record.');
      }
    } catch (error) {
      toast.error('An error occurred while deleting the record.');
    }

  }

  const handleChange = (event) => {
    const { target: { value } } = event;
    setPersonEmail(value);
    if (event)
      setDisableRunButton(true)
  };

  const handleRefresh = async (row) => {
    toast("Refreshing the table", {
      icon: <Info />,
      style: {
        borderRadius: '10px',
        background: '#ee9d0f',
        color: '#fff',
      },
    });

    try {
      const response = await fetch(
        `${baseURI}job_analysis/businesstool/get_spc_ec_filestatus?emailcampaignid=${row.emailcampaignid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log('Refresh successful:', result);

        toast.success('Refresh successful!', {
          style: {
            borderRadius: '10px',
            background: '#28a745',
            color: '#fff',
          },
        });

        fetchJobsData();
      } else {
        const errorDetails = await response.json();
        console.error('Error refreshing row:', errorDetails);

        toast.error(`Error: ${errorDetails.message || response.statusText}`, {
          style: {
            borderRadius: '10px',
            background: '#dc3545',
            color: '#fff',
          },
        });
      }
    } catch (error) {
      console.error('Error in handleRefresh:', error);

      toast.error('An unexpected error occurred.', {
        style: {
          borderRadius: '10px',
          background: '#dc3545',
          color: '#fff',
        },
      });
    }
  };

  const getStatusStyle = (value, mapping) => {
    let style = {};
    let label = '';
    let color = '';

    switch (value) {
      case 0:
        style = { backgroundColor: '#d3d3d3', color: 'black', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.idle || 'Idle';
        color = '#d3d3d3';
        break;
      case 1:
        style = { backgroundColor: '#ffa500', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.pending || 'Pending';
        color = '#ffa500';
        break;
      case 2:
        style = { backgroundColor: '#007bff', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.processing || 'Processing';
        color = '#007bff';
        break;
      case 3:
        style = { backgroundColor: '#28a745', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.completed || 'Completed';
        color = '#28a745';
        break;
      default:
        style = { backgroundColor: '#dc3545', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = 'Unknown';
        color = '#dc3545';
    }

    return { style, label, color };
  };

  const options = emailTemplates || null;

  // Data Columns
  const columns = [
    ...(roleId === 1 || roleId === 4
      ? [{ field: 'username', headerName: 'User', flex: 1, headerAlign: 'center', align: 'center' }]
      : []),
    { field: 'uploaded_on', headerName: 'Uploaded Date', flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: 'fileid',
      headerName: 'Campaign Id',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const fileId = params.value;
        const lastFourDigits = fileId.slice(-4);
        return <span>{lastFourDigits}</span>;
      },
    },
    // { field: 'to_process', headerName: 'Valid Records', flex: 1, headerAlign: 'center', align: 'center' },
    // { field: 'total_done', headerName: 'Records Processed', flex: 1, headerAlign: 'center', align: 'center' },

    {
      field: 'db_process_status',
      headerName: 'Validation',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {

        const { style, label } = getStatusStyle(params.value, {
          idle: 'Idle',
          pending: 'Pending',
          processing: 'Processing',
          completed: 'Completed',
        });
        const totalRecords = params.row.total_records;
        let badgeStyle = {
          backgroundColor: '#FFA500',
          color: '#000',
        };

        if (label === 'Idle') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#00BFFF';
        } else if (label === 'Processing') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#FFCC00';
        } else if (label === 'Completed') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#32CD32';
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              height: '100%',
              width: '100%',
            }}
          >
            {totalRecords > 0 && (
              <Badge
                badgeContent={totalRecords}
                color='primary'
                showZero
                max={999999}
                sx={{
                  '& .MuiBadge-badge': {
                    backgroundColor: badgeStyle.backgroundColor,
                    color: badgeStyle.color,
                    fontSize: '10px',
                    fontWeight: 'bold',
                    display: totalRecords === 0 ? 'none' : 'inline-block',

                  },
                }}
              >
                <span style={style}>{label}</span>
              </Badge>
            )}
          </div>
        );
      },
    }, 

    {
      field: 'data_prepare_status',
      headerName: 'Preparing Data',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { style, label } = getStatusStyle(params.value, {
          idle: 'Idle',
          pending: 'Pending',
          processing: 'Processing',
          completed: 'Completed',
        });
        const procesedDatarecords = params.row.to_process;
        let badgeStyle = {
          backgroundColor: '#FFA500',
          color: '#000',
        };

        if (label === 'Idle') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#00BFFF';
        } else if (label === 'Processing') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#FFCC00';
        } else if (label === 'Completed') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#32CD32';
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >

            <Badge
              badgeContent={procesedDatarecords}
              color='primary'
              showZero
              max={999999}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: badgeStyle.backgroundColor,
                  color: badgeStyle.color,
                  fontSize: '10px',
                  fontWeight: 'bold',
                  display: procesedDatarecords === 0 ? 'none' : 'inline-block',
                },
              }}
            >
              <span style={style}>{label}</span>
            </Badge>

          </div>
        );
      }
    },
    {
      field: 'email_process_status',
      headerName: 'Sending Emails',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { style, label } = getStatusStyle(params.value, {
          idle: 'Idle',
          pending: 'Pending',
          processing: 'Processing',
          completed: 'Completed',
        });

        const totalDonerecords = params.row.total_done;
        let badgeStyle = {
          backgroundColor: '#FFA500',
          color: '#000',
        };

        if (label === 'Idle') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#00BFFF';
        } else if (label === 'Processing') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#FFCC00';
        } else if (label === 'Completed') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#32CD32';
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >

            <Badge
              badgeContent={totalDonerecords}
              color='primary'
              showZero
              max={999999}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: badgeStyle.backgroundColor,
                  color: badgeStyle.color,
                  fontWeight: 'bold',
                  fontSize: '10px',
                  display: totalDonerecords === 0 ? 'none' : 'inline-block',
                },
              }}
            >
              <span style={style}>{label}</span>
            </Badge>

          </div>
        );
      }
    },
    { field: 'last_run_datetime', headerName: 'Last Run Datetime', flex: 1, headerAlign: 'center', minWidth: 140, align: 'center' },

    { field: 'total_runs', headerName: 'No of Runs', flex: 1, headerAlign: 'center', align: 'center' },


    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const row = params.row;
        const statuses = [
          row.db_process_status,
          row.data_prepare_status,
          row.email_process_status,
        ];
       


        const idleCount = statuses.filter(status => status === 0).length;
        // const isRowDisabled = rowStatus[row.fileid] === false || row.isPlayDisabled;
        const isDisabled = row.isPlayDisabled; 
        if (idleCount === statuses.length) {
          return (
            <>
            <Tooltip title="Run Job">
              
                <IconButton
                  aria-label="run-job"
                  {...(isDisabled
                    ? {}
                    : { onClick: () => {
                        handleOpenEmailTemp(row);
                      }})}
                  sx={{ color: 'blue' }}
                  disabled={isDisabled}
                  className={isDisabled ? 'disabled' : 'enabled'}
                >
                  <PlayArrow />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton
                  aria-label="refresh"
                  onClick={() => handleRefresh(row)}
                  sx={{ color: 'gray' }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton
                  aria-label="download"
                  onClick={() => handleDownloadBase64(row)}
                  sx={{ color: 'green' }}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
              {(roleId === 1 || roleId === 4) && <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(row)}
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
          
            </>
            
            
          );
        } else if (idleCount <= 3) {
          return (
            <>          
            <Tooltip title="Run Job">
                <IconButton
                  aria-label="run-job"
                  {...(isDisabled
                    ? {}
                    : { onClick: () => {
                        handleOpenEmailTemp(row);
                      }})}
                  sx={{ color: 'blue' }}
                  disabled={isDisabled}
                  className={isDisabled ? 'disabled' : 'enabled'}
                >
                  <PlayArrow />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton
                  aria-label="refresh"
                  onClick={() => handleRefresh(row)}
                  sx={{ color: 'gray' }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>    
              <Tooltip title="Download">
                <IconButton
                  aria-label="download"
                  onClick={() => handleDownloadBase64(row)}
                  sx={{ color: 'green' }}
                >
                  <CloudDownload />
                </IconButton>
              </Tooltip>
              {(roleId === 1 || roleId === 4) && <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(row)}
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
              
              
            </>
          );
        }
        return null;
      },
    },
  ];

  const buttonStyle = {
    width: `${buttonLabel.length * 15}px`, // Adjust multiplier as needed for ideal width
  };
  // const handleProcessAllJobs = async (selectedRows) => {
  //   try {
  //     console.log('Selected rows getting:', selectedRows);

  //     const formattedRequestDataArray = [];

  //     for (const row of selectedRows) {
  //       try {
  //         const userDetailsResponse = await fetch(
  //           `${baseURI}job_analysis/businesstool/get_spc_ec_filestatus?emailcampaignid=${row.emailcampaignid}`,
  //           {
  //             method: 'GET',
  //           }
  //         );

  //         if (!userDetailsResponse.ok) {
  //           const errorDetails = await userDetailsResponse.json();
  //           console.error('Error fetching user details:', errorDetails);
  //           toast.error(`Error fetching user details for ID: ${row.emailcampaignid}`);
  //           continue;
  //         }

  //         const userDetails = await userDetailsResponse.json();

  //         const mailTempResponse = await fetch(`${baseURI}job_analysis/businesstool/ec_get_details`, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify({ userid: row.userid }),
  //         });

  //         if (!mailTempResponse.ok) {
  //           const errorDetails = await mailTempResponse.json();
  //           console.error('Error fetching mail template:', errorDetails);
  //           toast.error(`Error fetching mail template for ID: ${row.emailcampaignid}`);
  //           continue;
  //         }

  //         const mailTemp = await mailTempResponse.json();

  //         const formattedRequestData = {
  //           userid: userDetails.details.userid,
  //           username: userDetails.details.username,
  //           office_position: mailTemp.office_position,
  //           phone_number: mailTemp.phone_number,
  //           email_domains: mailTemp.email_domains,
  //           email_template: mailTemp.email_templates.find((template) => template.name === "test2"),
  //           fileurllist: [userDetails.details.file_url],
  //         };

  //         formattedRequestDataArray.push(formattedRequestData);


  //       } catch (error) {
  //         console.error('Error processing job for row:', row, error);
  //         toast.error(`Unexpected error for ID: ${row.emailcampaignid}`);
  //       }
  //     }

  //     toast("Processing all jobs", {
  //       icon: <Info />,
  //       style: {
  //         borderRadius: '10px',
  //         background: '#0fc9ee',
  //         color: '#fff',
  //         width: '180px',
  //       },
  //     });

  //     for (const requestData of formattedRequestDataArray) {
  //       try {
  //         const response = await fetch(`${baseURI}job_analysis/businesstool/email_campaign`, {
  //           method: 'POST',
  //           headers: {
  //             'Content-Type': 'application/json',
  //           },
  //           body: JSON.stringify(requestData),
  //         });

  //         if (response.ok) {
  //           const result = await response.json();
  //           console.log('Job processed successfully:', result);
  //           toast.success('Job processed successfully!', {
  //             style: {
  //               borderRadius: '10px',
  //               background: '#28a745',
  //               color: '#fff',
  //             },
  //           });
  //           fetchJobsData(); 
  //         } else {
  //           const errorDetails = await response.json();
  //           console.error('Error processing job:', errorDetails);
  //           toast.error(`Error processing job: ${errorDetails.message}`);
  //         }
  //       } catch (error) {
  //         console.error('Error sending job request:', error);
  //         toast.error('An unexpected error occurred while processing the job.');
  //       }
  //     }

  //     toast.success('All jobs processed successfully!', {
  //       style: {
  //         borderRadius: '10px',
  //         background: '#28a745',
  //         color: '#fff',
  //       },
  //     });

  //   } catch (error) {
  //     console.error('Error processing jobs:', error);
  //     toast.error('An error occurred while processing jobs.');
  //   }
  // };

  return (
    <>
      <div className='job-extractor'>
        <Toaster position="top-right" reverseOrder={false} />
        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
          {isCircleLoading ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: 10,
              }}
            >
              <CircularProgressWithLabel value={progress} />
              <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <div className='jobextractor-table'>
              <Box>
                <CardContent style={{ paddingBottom: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                        <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                        <Box alignItems="center" className="email-campaign dd" style={{ display: 'flex' }}>
                          <div style={{marginRight: '15px'}}>
                            <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '16px' }}>* Click here to download the sample file for upload 
                              <a href='/seamless.csv' download style={{marginLeft: '15px'}}>
                                  Click Here
                              </a>
                            </Typography>
                          </div>
                          <TextField label="Search" variant="outlined" size="small" style={{ marginRight: '8px' }} />

                          <Tooltip title="Upload File" arrow>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ marginLeft: '8px' }}
                              className='button-t button-j'
                              onClick={handleClickOpen}
                            >
                              Upload File
                            </Button>
                          </Tooltip>
                          {/* {selectedRows.length > 0 && (
                           <Tooltip title="Process All Mails" arrow>
                              <Button
                                variant="contained"
                                color="secondary"
                                className='button-r'
                                style={{ marginLeft: '15px' }}
                                onClick={() => handleProcessAllJobs(selectedRows)}
                              >
                                Process All Emails
                              </Button>
                           </Tooltip>
                          
                        )} */}
                        </Box>

                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                          ...rows.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 20, 25, 50, 100, { value: -1, label: 'All' }]}
                        // checkboxSelection
                        getRowId={(row) => row.fileid}
                        // isRowSelectable={(params) =>
                        //   !(
                        //     params.row.to_process === 2 &&
                        //     params.row.db_process_status === 3 &&
                        //     params.row.email_process_status === 2 &&
                        //     params.row.db_validation_status === 2
                        //   )
                        // }
                        onRowSelectionModelChange={(newSelection) => {
                          const validSelections = newSelection.filter((id) =>
                            rows.some((row) => row.fileid === id)
                          );
                          const selectedData = rows.filter((row) =>
                            validSelections.includes(row.fileid)
                          );
                          // setSelectedRows(selectedData);
                        }}
                        className="job-extractor-table"
                        // disableRowSelectionOnClick
                        slots={{ noRowsOverlay: NoRowsOverlay }}
                        slotProps={{
                          noRowsOverlay: {
                            sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                          },
                        }}
                        autoHeight
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") handleClose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth="sm"
                  className="job-extractor"
                  disableEscapeKeyDown
                >
                  <DialogTitle>

                    <Typography variant='h6' className='dialog-title bottom-line'>  Campaign File Upload</Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8 }}
                      className='bg-primary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box id="drop-area" sx={{
                        padding: '20px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        border: '2px dashed #ccc',
                        marginBottom: '20px'
                      }}>
                        <label htmlFor="file-upload">
                          <Input
                            accept=".xlsx,.csv"
                            id="file-upload"
                            type="file"
                            onChange={handleFileUpload}
                            multiple
                          />
                          <Button variant="contained" component="span">Select Files</Button>
                        </label>
                        <Typography sx={{ marginTop: '10px', color: '#666' }}>
                          {fileNames ? `Selected file: ${fileNames}` : 'No file selected'}
                        </Typography>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={sendDataToBackend}
                      className={buttonClass}
                      type='submit' disabled={!disableButton}
                      style={{
                        ...buttonStyle,
                        backgroundColor: !disableButton ? '#d3d3d3' : '#1976d2',
                        color: !disableButton ? '#9e9e9e' : '#fff',
                      }}>{buttonLabel}</Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openToDetele}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => {
                    handledeleteclose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth="sm"
                  className="job-extractor"

                  disableEscapeKeyDown
                  PaperProps={{
                    sx: {
                      padding: '10px', 
                    }
                  }}
                >
                  <Typography variant='h6' className='dialog-title bottom-line'>Delete Confirmation</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handledeleteclose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>

                  <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to delete this record?
                    <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmDelete}
                        sx={{ marginRight: '8px' }}
                      >
                        Confirm
                      </Button>
                      <Button variant="contained" color='error' onClick={handledeleteclose}>
                        Cancel
                      </Button>
                    </Box>
                  </DialogContentText>
                </Dialog>

                <Dialog
                  open={openEmailTemp}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") handleClose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth="md"
                  className="job-extractor"
                  disableEscapeKeyDown
                  sx={{
                    '& .MuiPaper-root': {
                      overflowY: 'unset'

                    },
                    '& .MuiDialogContent-root': {
                      overflowY: 'unset'
                    }
                  }}
                >
                  <DialogTitle>

                    <Typography variant='h6' className='dialog-title bottom-line'>  Select Email Template and Domains</Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8 }}
                      className='bg-primary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent >
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box id="drop-area">
                        <Grid container spacing={2} sx={{ marginTop: '0px' }}>
                          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: '0px' }}>
                            <div className='email-accordion'>
                              <CustomDropdown options={options} onSelect={handleSelect} />
                            </div>

                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={6} style={{ paddingTop: '0px' }} >
                            <div className='email-campaign' >
                              <FormControl sx={{ width: '100%', margin: '0px' }}>
                                <InputLabel id="demo-multiple-checkbox-label">From email domains</InputLabel>
                                <Select
                                  labelId="demo-multiple-checkbox-label"
                                  id="demo-multiple-checkbox"
                                  multiple
                                  value={personEmail}
                                  onChange={handleChange}
                                  input={<OutlinedInput label="Email Credentials" />}
                                  renderValue={(selected) => selected.map((item) => item.domain).join(', ')}
                                >
                                  {emailDomains.map((name) => (
                                    <MenuItem key={name.domain} value={name}>
                                      <Checkbox checked={personEmail.some((email) => email.domain === name.domain)} />
                                      <ListItemText primary={name.domain} />
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </Grid>


                          {selectedTempValue?.template && (
                            <Grid item xs={12} sm={12} md={12}>
                              <div
                                className="email-template"
                                style={{
                                  padding: '10px',
                                  border: '1px solid rgb(215 213 213)',
                                  borderRadius: '5px',
                                  maxHeight: '300px',
                                  overflowY: 'auto'
                                }}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: selectedTempValue.template,
                                  }}
                                ></div>
                              </div>
                            </Grid>
                          )}
                        </Grid>

                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleReRun}
                      className="button-r"
                      type='submit' disabled={!disableRunButton}
                      style={{
                        ...buttonStyle,
                        backgroundColor: !disableRunButton ? '#d3d3d3' : '#1976d2',
                        color: !disableRunButton ? '#9e9e9e' : '#fff', width: '250px'
                      }}>Run Campaign</Button>
                  </DialogActions>
                </Dialog>
              </Box>
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default EmailCampaign;
