import { Button, Card, CardContent, Grid, IconButton, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { styled } from '@mui/material/styles';
import { DataGrid } from '@mui/x-data-grid';

import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';

import { PlayArrow, Refresh, Info } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import Badge from '@mui/material/Badge';
import StartIcon from '@mui/icons-material/Start';
import StopIcon from '@mui/icons-material/Stop';
const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;
const Input = styled('input')({
  display: 'none',
});

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const SeamlessAiIn = () => {
  const [open, setOpen] = useState(false);
  const [openToDetele, setopenToDetele] = useState(false);
  const [content, setContent] = useState('');
  const [fileNames, setFileNames] = useState([]);
  const [buttonLabel, setButtonLabel] = useState('Validate File');
  const [rows, setRows] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [buttonClass, setButtonClass] = useState('custom-button');
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleIsLoading] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const rowsRef = useRef([]);
  const intervalIdRef = useRef(null);
  const [rowStatuses, setRowStatuses] = useState({})
  const [status, setStatus] = React.useState(true);
  const handleClickOpen = () => {
    setOpen(true);
    setDisableButton(false)
  };
  const handledeleteclose = () => {
    setopenToDetele(false);
  }

  const handleClose = () => {

    setOpen(false);
    setContent('');
    setFileNames([]);
    setButtonLabel('Validate File');
    setOpen(false);

    const fileInput = document.getElementById('file-upload');
    if (fileInput) {
      fileInput.value = '';
    }
  };


  const userDetails = JSON.parse(localStorage.getItem("userdetails"));
  const username = userDetails.user.name
  const userid = userDetails.user.id
  const roleId = userDetails.user.roleId


  const fetchJobsData = async () => {
    try {
      const response = await fetch(`${baseURI}job_analysis/businesstool/slai_get_all_files?userid=${userid}`, {
        method: 'GET',
      });

      if (response.ok) {
        const data = await response.json();
        const processedRows = data.details.map((row) => {

          return { ...row };
        });

        setRows(processedRows);
        rowsRef.current = processedRows;

      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {
      console.error('fetching the error users', error);
    }
  };

  useEffect(() => {

    fetchJobsData();
    if (intervalIdRef.current) {
      clearInterval(intervalIdRef.current);
    }
    intervalIdRef.current = setInterval(() => {
      rowsRef.current.forEach((row) => {
        if (
          (row.validation_process >= 1 && row.validation_process < 3) ||
          (row.fetch_contacts_status >= 1 && row.fetch_contacts_status < 3) ||
          (row.fetch_emails_status >= 1 && row.fetch_emails_status < 3)
        ) {
          handleRefresh(row);
        }
      });
    }, 2 * 60 * 1000);

    return () => {
      if (intervalIdRef.current) {
        clearInterval(intervalIdRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isProcessing) return;

    let pollingInterval;

    const pollJobsData = async () => {
      await fetchJobsData();
      const processingJobs = rows.some((row) => row.status === 2);
      if (!processingJobs) {
        clearInterval(pollingInterval);
        setIsProcessing(false);
        toast.success("All jobs are now completed or idle.");
      }
    };

    pollingInterval = setInterval(pollJobsData, 5000);

    return () => {
      if (pollingInterval) clearInterval(pollingInterval);
    };
  }, [isProcessing, rows]);

  const sendDataToBackend = async () => {
    setProgress(10);
    setCircleIsLoading(true)
    try {
      setProgress(30);

      const docList = fileNames.map((name, index) => ({
        filename: name,
        base64: content[index],
      }));

      const response = await fetch(`${baseURI}job_analysis/businesstool/slai_file_upload`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userid, username, filelist: docList }),
      });
      setProgress(40);

      if (!response.ok) {
        if (response.status === 500) {
          toast.error(response.statusText);
        } else {
          throw new Error('Network response was not ok');
        }
      } else {
        setProgress(50);
        toast.success('File uploaded successfully!');

        fetchJobsData()

        setTimeout(() => {
          setProgress(70);
          setTimeout(() => {
            setProgress(100);
            setTimeout(() => {
              setCircleIsLoading(false);
            }, 300);
          }, 500);
        }, 800);

      }

      handleClose();
    } catch (error) {
      console.log(error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleFileUpload = (event) => {
    setDisableButton(false)
    const files = event.target.files; // Get all selected files

    if (files.length === 0) {
      toast.error("No file selected");
      return;
    }

    setFileNames(Array.from(files).map((file) => file.name));

    const toBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result.split(",")[1]); // Extract Base64 part
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    Array.from(files).forEach((file) => {
      if (file.name.endsWith('.xlsx')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const arrayBuffer = e.target.result;
          const workbook = XLSX.read(arrayBuffer, { type: 'array' });
          const sheetName = workbook.SheetNames[0];
          const sheet = workbook.Sheets[sheetName];
          const rawData = XLSX.utils.sheet_to_json(sheet, { raw: true, defval: null });

          const filteredData = rawData.map((row) => {
            const cleanedRow = {};
            Object.keys(row).forEach((key) => {
              if (!key.startsWith("__EMPTY")) {
                cleanedRow[key] = row[key];
              }
            });
            return cleanedRow;
          });

          const base64String = await toBase64(file);
          if (base64String) {
            setDisableButton(true)
          } else {
            setDisableButton(false)
          }
          setContent((prevContent) => [...prevContent, base64String]); // Store multiple files' content
          toast.success(`File uploaded and parsed successfully!`);
        };

        reader.readAsArrayBuffer(file);
      } else if (file.name.endsWith('.csv')) {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const csvContent = e.target.result;
          const rows = csvContent
            .split('\n')
            .filter((row) => row.trim() !== '') // Remove empty rows
            .map((row) => row.split(',')); // Split CSV content into arrays

          const headers = rows[0]; // First row as headers
          const data = rows.slice(1).map((row) =>
            headers.reduce((acc, header, index) => {
              acc[header] = row[index] || null;
              return acc;
            }, {})
          );

          const base64String = await toBase64(file);
          if (base64String) {
            setDisableButton(true)
          } else {
            setDisableButton(false)
          }

          setContent((prevContent) => [...prevContent, base64String]); // Store multiple files' content
          toast.success(`File uploaded and parsed successfully!`);
        };

        reader.readAsText(file);
      } else {
        toast.error(`Invalid file type it must be .xlsx or .csv file.`);
      }
    });
  };

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}>No records</Box>
  );

  const handleOpenEmailTemp = async (row) => {
    const currentTimestamp = new Date().toISOString();

    const disabledRows = JSON.parse(localStorage.getItem('disabledSRows')) || {};
    disabledRows[row.fileid] = currentTimestamp;
    localStorage.setItem('disabledSRows', JSON.stringify(disabledRows));
    try {
      toast.success("processing the seamless data", {
        style: {
          borderRadius: '10px',
          background: '#28a745',
          color: '#fff',
          width: 'auto',
          maxWidth: '600px',
          padding: '10px 15px',
        }
      });

      const docList = row.file_url;
      console.log(docList, "Checking docList")

      const response = await fetch(`${baseURI}job_analysis/businesstool/seamless_ai`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ fileurllist: docList }),
      });

      if (response.ok) {
        const result = await response.json();
        console.log('Response:', result);
        setIsProcessing(true);
        toast.success(result.message);
      } else {

        const errorData = await response.json();
        if (response.status === 500) {
          console.log('Error message:', errorData.exception_message);
          toast.error(`Error: ${errorData.exception_message}`, {
            style: {
              borderRadius: '10px',
              background: '#dc3545',
              color: '#fff',
              width: 'auto',
              maxWidth: '600px',
              padding: '10px 15px',
            },
          });


        } else {
          console.error(`Unexpected error: ${response.status}`);
          toast.error(`Unexpected error: ${response.statusText}`);
        }
      }
    } catch (error) {
      console.error('Error re-running job:', error);
    }

  }

  const handleDelete = (row) => {
    setDeleteId(row.id);
    setopenToDetele(true);
  }

  const handleConfirmDelete = async () => {
    try {
      const response = await fetch(`${baseURI}job_analysis/businesstool/del_slai_file`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ seamless_ai_file_id: deleteId }),
      });
      if (response.ok) {
        toast.success('Record deleted successfully.');
        handledeleteclose();
        fetchJobsData();
        setProgress(100)
        setCircleIsLoading(false)
      } else {
        toast.error('Failed to delete the record.');
      }
    } catch (error) {
      toast.error('An error occurred while deleting the record.');
    }

  }

  const handleRefresh = async (row) => {
    toast("Refreshing the table row", {
      icon: <Info />,
      style: {
        borderRadius: '10px',
        background: '#ee9d0f',
        color: '#fff',
      },
    });

    try {
      const response = await fetch(
        `${baseURI}job_analysis/businesstool/slai_get_spc_file?fileid=${row.fileid}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log('Refresh successful:', result);

        toast.success('Refresh successful!', {
          style: {
            borderRadius: '10px',
            background: '#28a745',
            color: '#fff',
          },
        });

        fetchJobsData();
      } else {
        const errorDetails = await response.json();
        console.error('Error refreshing row:', errorDetails);

        toast.error(`Error: ${errorDetails.message || response.statusText}`, {
          style: {
            borderRadius: '10px',
            background: '#dc3545',
            color: '#fff',
          },
        });
      }
    } catch (error) {
      console.error('Error in handleRefresh:', error);

      toast.error('An unexpected error occurred.', {
        style: {
          borderRadius: '10px',
          background: '#dc3545',
          color: '#fff',
        },
      });
    }
  };

  const getStatusStyle = (value, mapping) => {
    let style = {};
    let label = '';
    let color = '';
    console.log(value, "checking value")
    switch (value) {
      case 0:
        style = { backgroundColor: '#d3d3d3', color: 'black', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.idle || 'Idle';
        color = '#d3d3d3';
        break;
      case 1:
        style = { backgroundColor: '#ffa500', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.pending || 'Pending';
        color = '#ffa500';
        break;
      case 2:
        style = { backgroundColor: '#007bff', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.processing || 'Processing';
        color = '#007bff';
        break;
      case 3:
        style = { backgroundColor: '#28a745', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = mapping?.completed || 'Completed';

        color = '#28a745';
        break;
      default:
        style = { backgroundColor: '#dc3545', color: 'white', borderRadius: '5px', padding: '5px 10px' };
        label = 'Unknown';
        color = '#dc3545';
    }

    return { style, label, color };
  };

  // Data Columns
  const columns = [
    ...(roleId === 1 || roleId === 4
      ? [{ field: 'username', headerName: 'User Name', flex: 1, headerAlign: 'center', align: 'center' }]
      : []),
    { field: 'uploaded_on', headerName: 'Uploaded Date', flex: 1, headerAlign: 'center', align: 'center' },
    {
      field: 'fileid',
      headerName: 'File Id',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const fileId = params.value;
        const lastFourDigits = fileId.slice(-4);
        return <span>{lastFourDigits}</span>;
      },
    },
    {
      field: 'total_company', headerName: 'Total Company', flex: 1, headerAlign: 'center', align: 'center', renderCell: (params) => (
        <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        </Tooltip>
      )
    },
    {
      field: 'validation_process',
      headerName: 'Validation',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { style, label } = getStatusStyle(params.value, {
          idle: 'Idle',
          pending: 'Pending',
          processing: 'Processing',
          completed: 'Completed',
        });

        params.row.validation_process_label = label;

        const totalRecords = params.row.total_valid_company;
        let badgeStyle = {
          backgroundColor: '#FFA500',
          color: '#000',
        };

        if (label === 'Idle') {
          console.log(label, "Checking label")
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#00BFFF';
        } else if (label === 'Processing') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#FFCC00';
        } else if (label === 'Completed') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#32CD32';
        }

        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >

            <Badge
              badgeContent={totalRecords}
              color='primary'
              showZero
              max={999999}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: badgeStyle.backgroundColor,
                  color: badgeStyle.color,
                  fontSize: '12px',
                  fontWeight: 'bold',
                  display: totalRecords === 0 ? 'none' : 'inline-block',
                },
              }}
            >
              <span style={style}>{label}</span>
            </Badge>

          </div>
        );
      },
    },

    {
      field: 'fetch_contacts_status',
      headerName: 'Contacts Data',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { style, label } = getStatusStyle(params.value, {
          idle: 'Idle',
          pending: 'Pending',
          processing: 'Processing',
          completed: 'Completed',
        });
        params.row.fetch_contacts_label = label;

        const procesedDatarecords = params.row.total_contacts;
        let badgeStyle = {
          backgroundColor: '#FFA500',
          color: '#000',
        };

        if (label === 'Idle') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#00BFFF';
        } else if (label === 'Processing') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#FFCC00';
        } else if (label === 'Completed') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#32CD32';
        }
        return (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >

            <Badge
              badgeContent={procesedDatarecords}
              color='primary'
              showZero
              max={999999}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: badgeStyle.backgroundColor,
                  color: badgeStyle.color,
                  fontSize: '12px',
                  fontWeight: 'bold',
                  display: procesedDatarecords === 0 ? 'none' : 'inline-block',
                },
              }}
            >
              <span style={style}>{label}</span>
            </Badge>

          </div>
        );
      }
    },
    {
      field: 'fetch_emails_status',
      headerName: 'Emails Data',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const { style, label } = getStatusStyle(params.value, {
          idle: 'Idle',
          pending: 'Pending',
          processing: 'Processing',
          completed: 'Completed',
        });

        params.row.fetch_emails_label = label;

        const totalDonerecords = params.row.total_emails_fetched;
        let badgeStyle = {
          backgroundColor: '#FFA500',
          color: '#000',
        };

        if (label === 'Idle') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#00BFFF';
        } else if (label === 'Processing') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#FFCC00';
        } else if (label === 'Completed') {
          badgeStyle.backgroundColor = 'white';
          badgeStyle.color = '#32CD32';
        }
        return (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
            }}
          >

            <Badge
              badgeContent={totalDonerecords}
              color='primary'
              showZero
              max={999999}
              sx={{
                '& .MuiBadge-badge': {
                  backgroundColor: badgeStyle.backgroundColor,
                  color: badgeStyle.color,
                  fontWeight: 'bold',
                  fontSize: '12px',
                  display: totalDonerecords === 0 ? 'none' : 'inline-block',
                },
              }}
            >
              <span style={style}>{label}</span>
            </Badge>

          </div>
        );
      }
    },
    { field: 'last_run_datetime', headerName: 'Last Run Datetime', flex: 1, headerAlign: 'center', minWidth: 140, align: 'center' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => {
        const row = params.row;
        const statuses = [
          row.db_process_status,
          row.data_prepare_status,
          row.email_process_status,
        ];


        const isStart = !status;
        const fileid = row.fileid;
        const isDisabled = row.validation_process;
        console.log(row, 'disabled')
        const icon = isStart ? <StartIcon /> : <StopIcon />;
        const tooltipTitle = isStart ? 'Start Process' : 'Stop Process';
        const idleCount = statuses.filter(status => status === 0).length;
        // const isRowDisabled = rowStatus[row.fileid] === true || row.isPlayDisabled; 


        const handleStartnStop = async (row) => {

          const updatedStatus = !rowStatuses[row.fileid]
          const payload = { fileid: row.fileid, status: updatedStatus }
          console.log(payload, "Checking Palaod", status, "Check the staus")
          try {
            const response = await fetch(`${baseURI}job_analysis/businesstool/stop_slai_file`, {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(payload),
            });
            if (response.ok) {
              console.log(`Process ${isStart ? 'started' : 'stopped'} successfully.`);
              setRowStatuses((prev) => ({
                ...prev,
                [row.fileid]: updatedStatus, // Update only the specific row's status
              }))
              // Add logic to refresh or update the data grid here
            } else {
              console.error('Failed to update the status.');
            }
          } catch (error) {
            console.error('Error while triggering the API:', error);
          }
        };

        if (idleCount === statuses.length) {
          return (
            <>
              <Tooltip title="Run Job">
                <IconButton
                  aria-label="run-job"
                  {...(isDisabled
                    ? {}
                    : {
                      onClick: () => {
                        handleOpenEmailTemp(row);
                      }
                    })}
                  sx={{ color: 'blue' }}
                  disabled={isDisabled}
                  className={isDisabled ? 'disabled' : 'enabled'}
                >
                  <PlayArrow />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton
                  aria-label="refresh"
                  onClick={() => handleRefresh(row)}
                  sx={{ color: 'gray' }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
              {

                (row.validation_process_label === 'Processing' || row.validation_process_label === 'Pending' ||
                  row.fetch_contacts_label === 'Processing' || row.fetch_contacts_label === 'Pending' ||
                  row.fetch_emails_label === 'Processing' || row.fetch_emails_label === 'Pending') && (
                  <Tooltip title={rowStatuses[row.fileid] ? 'Stop Process' : 'Start Process'}>
                    <IconButton
                      aria-label={rowStatuses[row.fileid] ? 'stop-process' : 'start-process'}
                      onClick={() => handleStartnStop(row)}
                      sx={{ color: rowStatuses[row.fileid] ? 'green' : 'red' }}
                    >
                      {rowStatuses[row.fileid] ? <StartIcon /> : <StopIcon />}
                    </IconButton>
                  </Tooltip>
                )
              }

              {(roleId === 1 || roleId === 4) && <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(row)}
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </>

          );
        } else if (idleCount <= 3) {
          return (
            <>
              <Tooltip title="Run Job">
                <IconButton
                  aria-label="run-job"
                  {...(isDisabled
                    ? {}
                    : {
                      onClick: () => {
                        handleOpenEmailTemp(row);
                      }
                    })}
                  sx={{ color: 'blue' }}
                  disabled={isDisabled}
                  className={isDisabled ? 'disabled' : 'enabled'}
                >
                  <PlayArrow />
                </IconButton>
              </Tooltip>
              <Tooltip title="Refresh">
                <IconButton
                  aria-label="refresh"
                  onClick={() => handleRefresh(row)}
                  sx={{ color: 'gray' }}
                >
                  <Refresh />
                </IconButton>
              </Tooltip>
              {((row.validation_process_label === 'Processing' || row.validation_process_label === 'Pending' ||
                row.fetch_contacts_label === 'Processing' || row.fetch_contacts_label === 'Pending' ||
                row.fetch_emails_label === 'Processing' || row.fetch_emails_label === 'Pending')) && (
                  <Tooltip title={rowStatuses[row.fileid] ? 'Start Process' :  'Stop Process'}>
                    <IconButton
                      aria-label={rowStatuses[row.fileid] ? 'start-process' : 'stop-process'}
                      onClick={() => handleStartnStop(row)}
                      sx={{ color: rowStatuses[row.fileid] ? 'green' : 'red' }}
                    >
                      {rowStatuses[row.fileid] ? <StartIcon /> : <StopIcon />}
                    </IconButton>
                  </Tooltip>
                )}
              {(roleId === 1 || roleId === 4) && <Tooltip title="Delete">
                <IconButton
                  aria-label="delete"
                  onClick={() => handleDelete(row)}
                  sx={{ color: 'red' }}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>}
            </>
          );
        }
        return null;
      },
    },
  ];

  const buttonStyle = {
    width: `${buttonLabel.length * 15}px`, // Adjust multiplier as needed for ideal width
  };

  return (
    <>
      <div className='job-extractor'>
        <Toaster position="top-right" reverseOrder={false} />
        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
          {isCircleLoading ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: 10,
              }}
            >
              <CircularProgressWithLabel value={progress} />
              <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <div className='jobextractor-table'>
              <Box>
                <CardContent style={{ paddingBottom: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                        <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                        <Box alignItems="center" className="dd" style={{ display: 'flex' }}>
                          <div style={{ marginRight: '15px' }}>
                            <Typography variant='h6' sx={{ fontWeight: '600', fontSize: '16px' }}>* Click here to download the sample file for upload
                              <a href='/seamless_ai.xlsx' download style={{ marginLeft: '15px' }}>
                                Click Here
                              </a>
                            </Typography>
                          </div>
                          <TextField label="Search" variant="outlined" size="small" style={{ marginRight: '8px' }} />

                          <Tooltip title="Upload File" arrow>
                            <Button
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{ marginLeft: '8px' }}
                              className='button-t button-j'
                              onClick={handleClickOpen}
                            >
                              Upload File
                            </Button>
                          </Tooltip>
                        </Box>

                      </Box>

                    </Grid>
                    <Grid item xs={12}>
                      <DataGrid
                        rows={rows}
                        columns={columns}
                        initialState={{
                          ...rows.initialState,
                          pagination: { paginationModel: { pageSize: 10 } },
                        }}
                        pageSizeOptions={[10, 20, 25, 50, 100, { value: -1, label: 'All' }]}
                        // checkboxSelection
                        getRowId={(row) => row.fileid}
                        onRowSelectionModelChange={(newSelection) => {
                          const validSelections = newSelection.filter((id) =>
                            rows.some((row) => row.fileid === id)
                          );
                          const selectedData = rows.filter((row) =>
                            validSelections.includes(row.fileid)
                          );
                          // setSelectedRows(selectedData);
                        }}
                        className="job-extractor-table"
                        // disableRowSelectionOnClick
                        slots={{ noRowsOverlay: NoRowsOverlay }}
                        slotProps={{
                          noRowsOverlay: {
                            sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                          },
                        }}
                        autoHeight
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") handleClose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth="sm"
                  className="job-extractor"
                  disableEscapeKeyDown
                >
                  <DialogTitle>

                    <Typography variant='h6' className='dialog-title bottom-line'>  Seamless AI File Upload</Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8 }}
                      className='bg-primary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                      <Box id="drop-area" sx={{
                        padding: '20px',
                        textAlign: 'center',
                        borderRadius: '5px',
                        border: '2px dashed #ccc',
                        marginBottom: '20px'
                      }}>
                        <label htmlFor="file-upload">
                          <Input
                            accept=".xlsx,.csv"
                            id="file-upload"
                            type="file"
                            onChange={handleFileUpload}
                            multiple
                          />
                          <Button variant="contained" component="span">Select Files</Button>
                        </label>
                        <Typography sx={{ marginTop: '10px', color: '#666' }}>
                          {fileNames ? `Selected file: ${fileNames}` : 'No file selected'}
                        </Typography>
                      </Box>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={sendDataToBackend}
                      className={buttonClass}
                      type='submit' disabled={!disableButton}
                      style={{
                        ...buttonStyle,
                        backgroundColor: !disableButton ? '#d3d3d3' : '#1976d2',
                        color: !disableButton ? '#9e9e9e' : '#fff',
                      }}>{buttonLabel}</Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openToDetele}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => {
                    handledeleteclose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth="sm"
                  className="job-extractor"

                  disableEscapeKeyDown
                  PaperProps={{
                    sx: {
                      padding: '10px',
                    }
                  }}
                >
                  <Typography variant='h6' className='dialog-title bottom-line'>Delete Confirmation</Typography>
                  <IconButton
                    aria-label="close"
                    onClick={handledeleteclose}
                    sx={{ position: 'absolute', right: 8, top: 8 }}
                    className='bg-primary'
                  >
                    <CloseIcon />
                  </IconButton>

                  <DialogContentText id="alert-dialog-slide-description">
                    Are you sure you want to delete this record?
                    <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleConfirmDelete}
                        sx={{ marginRight: '8px' }}
                      >
                        Confirm
                      </Button>
                      <Button variant="contained" color='error' onClick={handledeleteclose}>
                        Cancel
                      </Button>
                    </Box>
                  </DialogContentText>
                </Dialog>

              </Box>
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default SeamlessAiIn;
