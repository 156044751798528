import React, { forwardRef } from "react";
import ReactQuill from "react-quill";
import EditorToolbar, { modules, formats } from "./EditorTollbar";
import "react-quill/dist/quill.snow.css";

export const ReusableEditor = forwardRef(({ value, onChange }, ref) => {
  const handleChange = (content) => {
    if (onChange) {
      onChange(content);
    }
  };

  return (
    <div className="text-editor">
      <EditorToolbar />
      <ReactQuill
        ref={ref} // Forward the ref here
        theme="snow"
        value={value}
        onChange={handleChange}
        placeholder={"Write something awesome..."}
        modules={modules}
        formats={formats}
        style={{ height: "100%", marginBottom: "30px" }}
      />
    </div>
  );
});

ReusableEditor.displayName = "ReusableEditor";

export default ReusableEditor;
