import { Fragment } from 'react'

import HistoryReportBreadcrumbs from '../../components/Breadcrumbs'
import SeamlessAiIn from './SeamlessAi';

const SeamlessAi = () => {
    return (
        <Fragment>
            <HistoryReportBreadcrumbs title='Seamless AI' data={[{ title: 'Campaign Dashboard' }]}/>
            <div>
                <SeamlessAiIn />
            </div>
        </Fragment>
    )
}

export default SeamlessAi;
