import {Button, Card, CardContent, Grid, IconButton,InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import GlobalVariable from '../../path/global';
import { toast, Toaster } from 'react-hot-toast';
import CircularProgressWithLabel from '../../components/reusableComponents/CircularProgressWithLabel';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';

const baseURI = GlobalVariable.BASE_API_GOOGLE_URL;

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ManageUserIn = () => {
  const [open, setOpen] = useState(false);
  const [rows, setRows] = useState([]);
  const [manageuserid, setManageuserid] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredRows, setFilteredRows] = useState([]);
  const [selectedURLToDelete, setSelectedURLToDelete] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
const [deleteOpen, setdeleteOpen] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isCircleLoading, setCircleIsLoading] = useState(false);
  const [ferrors, setErrors] = useState({});
  const [userName, setUserName] = useState('');
  const [userid, setUserId] = useState('')
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [officePosition, setPosition] = useState('');

  const [isProgress, setIsProgress] = useState(false);

  const [Domain, setDomain] = useState('');
  const [Password, setPassword] = useState('');

  const [DomainPasswordVisible, setDomainPasswordVisible] = useState(false);
  const [modelName, setModelName] = useState("add");
  const [emailDomains, setEmailDomains] = useState([
    { Domain: "", Password: "", showPassword: false },
  ]);

  const validateFields = () => {
    const newErrors = {};
  
    if (!phoneNumber || phoneNumber.trim() === '') {
      newErrors.phoneNumber = 'Phone Number is required';
    } else if (!/^\d{10}$/.test(phoneNumber)) {
      newErrors.phoneNumber = 'Phone Number must be 10 digits';
    }
  
    if (!officePosition || officePosition.trim() === '') {
      newErrors.officePosition = 'Office Position is required';
    }
   
    if (!Domain || Domain.trim() === '') {
      newErrors.Domain = 'Domain is required';
    } else if (!/\S+@\S+\.\S+/.test(Domain)) {
      newErrors.Domain = 'Enter a valid email';
    }
  
    if (!Password || Password.trim() === '') {
      newErrors.Password = 'Domain Password is required';
    } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=-]{6,20}$/.test(Password)) {
      newErrors.Password = 'Password must be at least 6 characters and can include letters, numbers, and symbols (!@#$%^&*()_+=-)';
    }
  
    emailDomains.forEach((item, index) => {
      if (!item.Domain || item.Domain.trim() === '') {
        newErrors[`emailDomains.${index}.Domain`] = `Domain is required for entry ${index + 1}`;
      } else if (!/\S+@\S+\.\S+/.test(item.Domain)) {
        newErrors[`emailDomains.${index}.Domain`] = 'Enter a valid email';
      }
  
      if (!item.Password || item.Password.trim() === '') {
        newErrors[`emailDomains.${index}.Password`] = `Password is required for entry ${index + 1}`;
      } else if (!/^[a-zA-Z0-9!@#$%^&*()_+=-]{6,20}$/.test(item.Password)) {
        newErrors[`emailDomains.${index}.Password`] = 'Password must be at least 6 characters and can include letters, numbers, and symbols (!@#$%^&*()_+=-)';
      }
    });
  
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const addemailDomains = () => {
    const newEmailDomain = {
      domainid: "",
      Domain: "",
      Password: "",
      showPassword: false,
    };
      setEmailDomains([...emailDomains, newEmailDomain]);    
  }
  const togglePasswordVisibility = (index) => {
    const updatedDomains = [...emailDomains];
    updatedDomains[index].showPassword = !updatedDomains[index].showPassword;
    setEmailDomains(updatedDomains);
  };

  const deleteEmailDomain = (index) => {
    const updatedDomains = emailDomains.filter((_, i) => i !== index);
    setEmailDomains(updatedDomains);
  };

  const updateEmailDomain = (index, key, value) => {
    const updatedDomains = [...emailDomains];
    updatedDomains[index][key] = value;
    setEmailDomains(updatedDomains);
  }

  // const handleOnChange = (e) => {
  //   setManageuserid(e.target.value);
  //   // setUserName(e.target.value);
  //   // setEmail(e.target.value);
  //   // setPhoneNumber(e.target.value);
  //   // setPosition(e.target.value);
  //   // setSeamlessEmail(e.target.value);
  //   // setSeamlessPassword(e.target.value);
  // };

  // const handleClickShowPassword = () => {
  //   setSeamlessPasswordVisible(!seamlessPasswordVisible);
  // };

  const handleClickDShowPassword=() => {
    setDomainPasswordVisible(!DomainPasswordVisible);
  }

  const userDetails = JSON.parse(localStorage.getItem("userdetails"));

  const ClickOpen = (row) => {
    setOpen(true);
    setModelName("add")
    setManageuserid('')
    setUserName(row.name);
    setEmail('');
    setPhoneNumber('');
    setPosition('');
    // setSeamlessEmail('');
    // setSeamlessPassword('');
    setSelectedURLToDelete(false);
    setDeleteId(null);
    setEmailDomains([]);
    setUserId(row.userid)
  }

  const handleClose = () => {
    setOpen(false);
    setManageuserid('')
    setUserName('');
    setEmail('');
    setPhoneNumber('');
    setPosition('');
    // setSeamlessEmail('');
    // setSeamlessPassword('');
    setSelectedURLToDelete(false);
    setDeleteId(null);
    setdeleteOpen(false)
    setEmailDomains([]);
  };

  const fetchJobsData = async () => {
    setProgress(10)
    setCircleIsLoading(true)
    try {
      setProgress(40)
      setProgress(70)
      const response = await fetch(`${baseURI}job_analysis/businesstool/viewallusers?companyid=${userDetails.user.companyId}`, {
        method: 'GET'
      });

      if (response.ok) {
        const data = await response.json();
        setRows(data.details)
        setFilteredRows(data.details);
        setProgress(100)
        setCircleIsLoading(false)
      } else {
        console.error(`Error: ${response.status} - ${response.statusText}`);
      }
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchJobsData()
  }, [])

  const handleDeleteClick = (row) => {
    setSelectedURLToDelete(true);
    setDeleteId(row.manageuserid);
    if(row.manageuserid){
      setdeleteOpen(true);
    }else{
      toast.error("Manage userid is not exit")
    }
   
    setModelName("delete")

    console.log(row)
  };

  const handleEdit = async (row, modelName) => {
    setManageuserid(row.manageuserid);
    console.log(modelName)
    if(row.manageuserid && modelName === "Edit"){
      setIsProgress(true)
      const response = await axios.get(
        `${baseURI}job_analysis/businesstool/viewoneuser`,
        {
          params: { manageuserid: row.manageuserid },
        }
      );
    
      const userDetails = response.data.details;
      setUserId(userDetails.userid)
      setUserName(row.name || "");
      setPhoneNumber(userDetails.phone_number || "");
      setPosition(userDetails.office_position || "");
      // setSeamlessEmail(userDetails.seamless_credentials?.email || "");
      // setSeamlessPassword(userDetails.seamless_credentials?.password || "");
    
      if (userDetails.email_domains.length > 0) {
        const firstDomain = userDetails.email_domains[0];
        setDomain(firstDomain.domain || "");
        setPassword(firstDomain.password || "");
      }
    
      const mappedEmailDomains = userDetails.email_domains.slice(1).map((domain) => ({
        domainid: domain.domainid,
        Domain: domain.domain,
        Password: domain.password,
        showPassword: false,
      }));
    
      setEmailDomains(mappedEmailDomains);
    
      setModelName("edit");
      setOpen(true);
      setIsProgress(false)
    }else if(modelName === "Add") {
      ClickOpen(row)
    }else{
      toast.error("Add user details before edit.")
    }
  };
  
  

  const handleSubmit = async () => {
  
    setIsProgress(true)

    if (selectedURLToDelete || modelName === "delete") {
      console.log("delete")
      try {
        const response = await fetch(`${baseURI}job_analysis/businesstool/deluser`, {
          method: 'DELETE',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ manageuserid: deleteId }),
        });
        if (response.ok) {
          toast.success('Record deleted successfully.');
          handleClose();
          fetchJobsData();
        } else {
          toast.error('Failed to delete the record.');
        }
      } catch (error) {
        toast.error('An error occurred while deleting the record.');
      }
    } else if (modelName === "edit") {
      if(!validateFields()){
        return;
      }
      const updatedRequestBody = {
        userid: userid,
        manageuserid,
        phone_number: phoneNumber,
        office_position: officePosition,
        // seamless_credentials: {
        //   email: seamlessEmail,
        //   password: seamlessPassword,
        // },
        email_domains: [
          {
            domainid: "",
            domain: Domain,
            password: Password,
          },
          ...emailDomains.map((domain) => ({
            domainid: domain.domainid || "",
            domain: domain.Domain,
            password: domain.Password,
          })),
        ],
      };

      try {
        const response = await axios.post(
          `${baseURI}job_analysis/businesstool/addnupdateuser`,
          updatedRequestBody
        );
        if (response.status === 200) {
          toast.success('Record updated successfully.');
          handleClose();
          fetchJobsData();
          setIsProgress(false)
        } else {
          toast.error('Failed to update the record.');
        }
      } catch (error) {
        toast.error('An error occurred while updating the record.');
      }
    } else {
      if(!validateFields()){
        setIsProgress(false)
        return
      }
      const updatedEmailDomains = [
        {
          domainid: "",
          domain: Domain || "",
          password: Password || "",
        },
        ...emailDomains
          .filter((domain) => domain.Domain && domain.Password)
          .map(({ Domain, Password, domainid }) => ({
            domainid: domainid || "",
            domain: Domain,
            password: Password,
          })),
      ];
      
      const seamlessUserData = {
        userid: userid,
        manageuserid: "",
        phone_number: phoneNumber,
        office_position: officePosition,
        // seamless_credentials: {
        //   email: seamlessEmail,
        //   password: seamlessPassword,
        // },
        email_domains: updatedEmailDomains,
      };

      try {
        const response = await fetch(`${baseURI}job_analysis/businesstool/addnupdateuser`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(seamlessUserData),
        });
        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData)
          toast.success(responseData.message);
          handleClose();
          fetchJobsData();
        } else {
          toast.error('Failed to add the record.');
        }
      } catch (error) {
        toast.error('An error occurred while adding the record.');
      }
    }
  };
  
  

  const NoRowsOverlay = () => (
    <Box sx={{ p: 2, textAlign: 'center', color: 'gray' }}>No records</Box>
  );

  // Data Columns
  const columns = [
    {
      field: 'name', headerName: 'Name', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
        <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        </Tooltip>
      )
    },
    {
      field: 'office_position', headerName: 'Office Position', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
        <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        </Tooltip>
      )
    },
    {
      field: 'phone_number', headerName: 'Phone Number', flex: 2, headerClassName: 'bg-primary text-white text-size', renderCell: (params) => (
        <Tooltip title={<div style={{ fontSize: '14px' }}> {params.value}</div>} arrow>
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.value}
          </div>
        </Tooltip>
      )
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 3,
      renderCell: (params) => (
        <>


          <Tooltip title="Delete" arrow>
            <IconButton
              size="small"
              onClick={() => handleDeleteClick(params.row)}
              className='button-a'
            >
              <DeleteIcon color="primary" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit User" arrow>
            <IconButton
              size="small"
              onClick={() => handleEdit(params.row, "Edit")}
              className='button-a'
            >
              <EditIcon color="primary" />
            </IconButton>
          </Tooltip>
          {!params.row.manageuserid && (
          <Tooltip title="Add Manage User" arrow>
            <IconButton
              size="small"
              onClick={() => handleEdit(params.row, "Add")}
              className='button-a'
            >
              <AddIcon color="primary" />
            </IconButton>
          </Tooltip>
          )}
        </>
      ),
      headerClassName: 'bg-primary text-white text-size',
    },
  ];



  const handleSearchChange = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);
    const filtered = rows.filter((row) =>
      row.name.toLowerCase().includes(value)
    );
    setFilteredRows(filtered);
  };

  return (
    <>
      <div className='blocked-companies'>
        <Toaster position="top-right" reverseOrder={false} />
        <Card variant="outlined" sx={{ borderRadius: '10px' }}>
          {isCircleLoading ? (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '70vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.5)',
                zIndex: 10,
              }}
            >
              <CircularProgressWithLabel value={progress} />
              <Typography variant="body1" sx={{ marginTop: 2, color: 'text.secondary' }}>
                Loading...
              </Typography>
            </Box>
          ) : (
            <div className='jobextractor-table'>
              <Box>
                <CardContent style={{ paddingBottom: '10px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Box className="JobExtractorView" alignItems="center" justifyContent="space-between">
                        <Typography variant='h5' sx={{ fontWeight: '600' }}></Typography>
                        <Box alignItems="center" className="dd" style={{ display: 'flex' }}>

                          <TextField
                            label="Search"
                            variant="outlined"
                            size="small"
                            style={{ marginRight: '8px' }}
                            value={searchTerm}
                            onChange={handleSearchChange}
                          />
                        </Box>

                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <DataGrid
                        rows={filteredRows}
                        columns={columns}
                        getRowId={(row) => row.manageuserid || row.userid}
                        className="job-extractor-table"
                        disableRowSelectionOnClick
                        slots={{ noRowsOverlay: NoRowsOverlay }}
                        slotProps={{
                          noRowsOverlay: {
                            sx: { fontSize: '16px', fontWeight: 'bold', color: 'gray' },
                          },
                        }}
                        initialState={{
                          pagination: {
                            paginationModel: { pageSize: 10, page: 0 },
                          },
                        }}
                        pageSizeOptions={[10, 25, { value: -1, label: 'All' }]}
                      />
                    </Grid>
                  </Grid>
                </CardContent>

                <Dialog
                  open={open}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") handleClose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth={modelName ==="delete" ? "sm":"md"}
                  className="manage-user"
                  disableEscapeKeyDown
                >
                  {isProgress && (
                    <Box sx={{ display: 'flex', backgroundColor: '#f3f3f38c', 
                    position: 'absolute', 
                    zIndex: '1405',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                    )}
                  <DialogTitle>
                    <Typography variant='h6' className='dialog-title bottom-line'>   
                     
                   {modelName === "add" ? "Add User" : "Edit User"}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8 }}
                      className='bg-primary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    style={{ padding: '0px' }}
                  >
                      <DialogContentText
                        id="alert-dialog-slide-description"
                        style={{ marginTop: '0px', padding: '0px' }}
                      >
                        <Box id="drop-area"
                          sx={{
                            padding: ' 10px 20px',
                            textAlign: 'center',
                            borderRadius: '5px',
                            marginBottom: '20px'
                          }}
                        >
                          <Box sx={{ marginBottom: '10px' }}>
                            <fieldset className='fieldset'>
                              <legend className='legend'>User Information</legend>
                              <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                style={{ padding: '0px' }}
                              >                             
                                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
                                       
                                          <TextField
                                            size="small"
                                            margin="normal"
                                            fullWidth
                                            type="text"
                                            label="User Name"
                                            placeholder="Username"
                                            value={userName}
                                            onChange={(e) => setUserName(e.target.value)}
                                            disabled
                                            sx={{ width: "100%" }}
                                          />
                                     
                                      </Grid>
                                
                                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Phone Number"
                                    placeholder='Phone Number'
                                    value={phoneNumber}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (/^\d*$/.test(value)  && value.length <= 10) {
                                        setPhoneNumber(value);
                                      }
                                    }}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.phoneNumber}
                                    helperText={ferrors.phoneNumber}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={4} md={4} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Office Position"
                                    placeholder='Office Position'
                                    value={officePosition}
                                    onChange={(e) => setPosition(e.target.value)}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.officePosition}
                                    helperText={ferrors.officePosition}
                                  />
                                </Grid>

                              </Grid>
                            </fieldset>
                            {/* <Box
                              style={{ marginBottem: '15px' }}
                            >  <fieldset>
                              <legend>Seamless Credentials</legend>
                              
                                <Grid
                                  container
                                  spacing={2}
                                  alignItems={"center"}
                                  style={{ padding: '0px' }}
                                >
                                  <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
                                    <TextField
                                      size='small'
                                      margin="normal"
                                      fullWidth
                                      type="text"
                                      label="Seamless Email"
                                      placeholder='SeamlessEmail'
                                      value={seamlessEmail}
                                      onChange={(e) => setSeamlessEmail(e.target.value)}
                                      sx={{ width: "100%" }}
                                      error={!!ferrors.seamlessEmail}
                                      helperText={ferrors.seamlessEmail}
                                    />
                                  </Grid>
                                  <Grid item xs={12} sm={6} md={6} style={{ paddingTop: '10px' }}>
                                    <TextField
                                      size='small'
                                      margin="normal"
                                      fullWidth
                                      type={seamlessPasswordVisible ? "text" : "password"}                                    
                                      label="Seamless Password"
                                      placeholder='SeamlessPassword'
                                      value={seamlessPassword}
                                      onChange={(e) => setSeamlessPassword(e.target.value)}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position="end" >
                                            <IconButton
                                              aria-label="toggle password visibility"
                                              onClick={handleClickShowPassword}
                                              edge="end"
                                              className='password'
                                              sx={{color: '#1976d2 !important'}}
                                            >
                                              {seamlessPasswordVisible ? <VisibilityOff style={{color: '#333'}}/> : <Visibility style={{color: '#333'}}/>}
                                            </IconButton>
                                          </InputAdornment>
                                        ),
                                      }}
                                      sx={{ width: "100%" }}
                                      error={!!ferrors.seamlessPassword}
                                      helperText={ferrors.seamlessPassword}
                                    />
                                  </Grid>
                                </Grid>
                              </fieldset>
                            </Box> */}

                            <Box>
                            <fieldset>
                            <legend>Email Domains</legend>
                              
                              <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                style={{ padding: '0px' }}
                              >
                                <Grid item xs={12} sm={5} md={5} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type="text"
                                    label="Domain"
                                    placeholder='Domain'
                                    value={Domain}
                                    onChange={(e) => setDomain(e.target.value)}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.Domain}
                                      helperText={ferrors.Domain}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={5} md={5} style={{ paddingTop: '10px' }}>
                                  <TextField
                                    size='small'
                                    margin="normal"
                                    fullWidth
                                    type={DomainPasswordVisible ? "text" : "password"}                                     
                                    label="Password"
                                    placeholder='Password'
                                    value={Password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="end" >
                                          <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickDShowPassword}
                                            edge="end"
                                            className='password'
                                          >
                                            {DomainPasswordVisible ? <VisibilityOff style={{color: '#333'}}/> : <Visibility style={{color: '#333'}}/>}
                                          </IconButton>
                                        </InputAdornment>
                                      ),
                                    }}
                                    sx={{ width: "100%" }}
                                    error={!!ferrors.Password}
                                      helperText={ferrors.Password}
                                  />
                                </Grid>
                                <Grid item xs={12} sm={2} md={2} style={{ paddingTop: '0px', paddingRight:'16px' }}>
                                  <IconButton
                                    size="small"
                                    onClick={addemailDomains}
                                    
                                    style={{backgroundColor: '#1976d2', width: '38px', height: '38px', borderRadius: '50px'}}
                                  >
                                    <AddIcon className='btn' style={{width: '0.8em', height: '0.8em'}}/>                                  
                                  </IconButton>
                                </Grid>
                                {emailDomains.map((item, index) => (
                                    <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: "8px", paddingLeft: '15px', paddingTop: '10px' }}>
                                      <Grid item xs={12} sm={5} md={5} >
                                        <TextField
                                          size="small"
                                          fullWidth
                                          value={item.Domain}
                                          onChange={(e) => updateEmailDomain(index, "Domain", e.target.value)}
                                         
                                          label="Domain"
                                          error={!!ferrors[`emailDomains.${index}.Domain`]}
                                          helperText={ferrors[`emailDomains.${index}.Domain`]}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={5} md={5}>
                                        <TextField
                                          size="small"
                                          fullWidth
                                          type={item.showPassword ? "text" : "password"}
                                          value={item.Password}
                                          onChange={(e) => updateEmailDomain(index, "Password", e.target.value)}
                                          InputProps={{
                                            endAdornment: (
                                              <InputAdornment position="end" >
                                                <IconButton
                                                  aria-label="toggle password visibility"
                                                  onClick={() => togglePasswordVisibility(index)}
                                                  edge="end"
                                                  className='password'
                                                >
                                                  {item.showPassword ? (
                                                      <VisibilityOff style={{ color: "#333" }} />
                                                    ) : (
                                                      <Visibility style={{ color: "#333" }} />
                                                    )}
                                                </IconButton>
                                              </InputAdornment>
                                            ),
                                          }}
                                          label="Password"
                                          error={!!ferrors[`emailDomains.${index}.Password`]}
                                          helperText={ferrors[`emailDomains.${index}.Password`]}
                                        />
                                      </Grid>
                                      <Grid item xs={12} sm={2} md={2} style={{paddingLeft: '0px'}}>
                                        <IconButton size="small" style={{backgroundColor: '#1976d2', width: '38px', height: '38px', borderRadius: '50px'}} onClick={() => deleteEmailDomain(index)}>
                                          <DeleteIcon style={{width: '0.8em', height: '0.8em'}}/>
                                        </IconButton>
                                      </Grid>
                                    </Grid>
                                  ))}
                              </Grid>
                              
                              </fieldset>
                            </Box>
                          </Box>
                          <Box>
                            <label htmlFor="file-upload">
                              <Button variant="contained" component="span" onClick={handleSubmit}> {
   modelName === "edit"
    ? "Update User Details"
    : "Submit User Details"}</Button>
                            </label>
                          </Box>
                        </Box>
                      </DialogContentText>
                 
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={deleteOpen}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={(event, reason) => {
                    if (reason !== "backdropClick") handleClose();
                  }}
                  aria-describedby="alert-dialog-slide-description"
                  fullWidth
                  maxWidth={modelName ==="delete" ? "sm":"md"}
                  className="manage-user"
                  disableEscapeKeyDown
                >
                  {isProgress && (
                    <Box sx={{ display: 'flex', backgroundColor: '#f3f3f38c', 
                    position: 'absolute', 
                    zIndex: '1405',
                    width: '100%',
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center' }}>
                      <CircularProgress />
                    </Box>
                    )}
                  <DialogTitle>
                    <Typography variant='h6' className='dialog-title bottom-line'>   
                     
                   {"Delete Confirmation"}
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleClose}
                      sx={{ position: 'absolute', right: 8, top: 8 }}
                      className='bg-primary'
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent
                    style={{ padding: '0px' }}
                  >

                      <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this record?
                        <Box sx={{ marginTop: '20px', textAlign: 'right' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                            sx={{ marginRight: '8px' }}
                          >
                            Confirm
                          </Button>
                          <Button variant="contained" color='error' onClick={handleClose}>
                            Cancel
                          </Button>
                        </Box>
                      </DialogContentText>
                   
                  </DialogContent>
                </Dialog>
              </Box>
            </div>
          )}
        </Card>
      </div>
    </>
  );
}

export default ManageUserIn;
